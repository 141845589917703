<template>
  <div class="admin-level">
    <div class="container">
      <h2>Добавление и редактирование уровней обучения</h2>
      <div class="select-directions">
        <div class="tags-p">Выбранное направление</div>
        <el-select
          v-model="value"
          filterable
          placeholder="Выберите направление обучения"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-dialog
          v-model="dialogEditLevel"
          title="Редактирование уровня обучения"
          width="40%"
        >
          <div>
            <div class="demo-input-suffix">
              <el-row :gutter="20">
                <span class="demo-input-label">Название уровня</span>
                <el-input
                  v-model="editLevelForm.name"
                  placeholder="Введите название уровня"
                />
              </el-row>
              <el-row :gutter="20">
                <span class="demo-input-label">Описание уровня</span>
                <el-input
                  v-model="editLevelForm.description"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  type="textarea"
                  placeholder="Введите описание уровня"
                >
                </el-input>
              </el-row>
            </div>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogEditLevel = false">Отменить</el-button>
              <el-button type="primary" @click="editLevel"
                >Сохранить уровень</el-button
              >
            </span>
          </template>
        </el-dialog>

        <el-dialog
          v-model="dialogAddTask"
          title="Добавить задачу уровня"
          width="40%"
        >
          <div>
            <div
              style="position: relative; display: flex; flex-direction: column;"
            >
              <span class="demo-input-label">Тип задачи</span>
              <div class="LevelTypeForm">
                <input
                  type="radio"
                  name="taskType"
                  value="задача"
                  v-model="addTaskType"
                  :checked="addTaskType == 'задача'"
                />
                <label style="margin-right: 20px;">Задача</label>
                <input
                  type="radio"
                  name="taskType"
                  value="тест"
                  v-model="addTaskType"
                  :checked="addTaskType == 'тест'"
                />
                <label>Тест</label>
              </div>
            </div>

            <template v-if="addTaskType == 'задача'">
              <div class="demo-input-suffix">
                <el-row :gutter="20">
                  <span class="demo-input-label">Название задачи</span>
                  <el-input
                    v-model="addTasksForm.name"
                    placeholder="Введите название задачи"
                  />
                </el-row>
                <el-row :gutter="20" style="padding-top: 30px;">
                  <span class="demo-input-label">Описание задачи</span>
                  <div class="QEditor">
                    <QuillEditor
                      ref="QuillEditorAdd"
                      :modules="modules"
                      toolbar="full"
                      theme="snow"
                      contentType="html"
                      :content="addTasksForm.description"
                      @textChange="handleEditorAddChanges"
                      @ready="handleEditorAddReady"
                    />
                  </div>
                  <el-row :gutter="20">
                    <el-upload
                      class="upload-demo"
                      :on-remove="handleAddRemove"
                      action="#"
                      :auto-upload="false"
                      :on-change="handleAddMaterialSuccess"
                      :limit="10"
                      :file-list="addTasksForm.materials"
                    >
                      <el-button size="small" type="primary"
                        >Загрузить материалы</el-button
                      >
                      <template #tip> </template>
                    </el-upload>
                  </el-row>
                </el-row>
              </div>
            </template>
            <template v-else>
              <el-select
                v-model="addTasksForm.test_id"
                class="m-2"
                placeholder="Select"
                size="large"
              >
                <el-option
                  v-for="item in UserTestsList"
                  :key="item.value"
                  :label="`${item.name} (${item.description})`"
                  :value="item.id"
                />
              </el-select>
            </template>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogAddTask = false">Отменить</el-button>
              <el-button type="primary" @click="addTask"
                >Добавить задачу</el-button
              >
            </span>
          </template>
        </el-dialog>

        <el-dialog
          v-model="dialogEditTask"
          title="Редактировать задачу уровня"
          width="40%"
        >
          <div>
            <div
              style="position: relative; display: flex; flex-direction: column;"
            >
              <span class="demo-input-label">Тип задачи</span>
              <div class="LevelTypeForm">
                <input
                  type="radio"
                  name="taskType"
                  value="задача"
                  v-model="addTaskType"
                  :checked="addTaskType == 'задача'"
                />
                <label style="margin-right: 20px;">Задача</label>
                <input
                  type="radio"
                  name="taskType"
                  value="тест"
                  v-model="addTaskType"
                  :checked="addTaskType == 'тест'"
                />
                <label>Тест</label>
              </div>
            </div>

            <template v-if="addTaskType == 'задача'">
              <div class="demo-input-suffix">
                <el-row :gutter="20">
                  <span class="demo-input-label">Название задачи</span>
                  <el-input
                    v-model="editTasksForm.name"
                    placeholder="Введите название задачи"
                  />
                </el-row>
                <el-row :gutter="20" style="padding-top: 30px;">
                  <span class="demo-input-label">Описание задачи</span>
                  <!-- <el-input
                    v-model="editTasksForm.description"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    type="textarea"
                    placeholder="Введите описание задачи"
                  >
                  </el-input> -->
                  <div
                    class="QEditor"
                    style="position: relative; height: 800px; padding-bottom: 100px;"
                  >
                    <QuillEditor
                      ref="QuillEditor"
                      :modules="modules"
                      toolbar="full"
                      theme="snow"
                      contentType="html"
                      :content="editTasksForm.description"
                      @textChange="handleEditorEditChanges"
                      @ready="handleEditorEditReady"
                    />
                  </div>
                  <el-upload
                    class="upload-demo"
                    :on-remove="handleEditRemove"
                    action="#"
                    :auto-upload="false"
                    :on-change="handleEditMaterialSuccess"
                    :limit="10"
                    :file-list="editTasksForm.materials"
                  >
                    <el-button size="small" type="primary"
                      >Загрузить материалы</el-button
                    >
                    <template #tip> </template>
                  </el-upload>
                </el-row></div></template
            ><template v-else>
              <el-select
                v-model="editTasksForm.test_id"
                class="m-2"
                placeholder="Select"
                size="large"
              >
                <el-option
                  v-for="item in UserTestsList"
                  :key="item.value"
                  :label="`${item.name} (${item.description})`"
                  :value="item.id"
                />
              </el-select>
            </template>
          </div>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogEditTask = false">Отменить</el-button>
              <el-button type="warning" @click="deleteTask">Удалить</el-button>
              <el-button type="primary" @click="editTask"
                >Сохранить задачу</el-button
              >
            </span>
          </template>
        </el-dialog>
      </div>
      <el-scrollbar height="500px">
        <div v-for="(level, i) in filterLevels" :key="i">
          <div class="card-level">
            <div class="level-info">
              <div class="level-header">
                <div class="level-name">
                  {{ level.name }}
                </div>
                <div class="level-btn">
                  <button @click="openEditLevel(level)">
                    Редактировать
                  </button>
                </div>
              </div>

              <div class="level-desc">{{ level.description }}</div>
              <div class="level-desc">
                Задачи уровня
                <div class="btn-levels" @click="openAddTasks(level)">
                  Добавить
                </div>
              </div>
            </div>
            <div class="level-task">
              <div class="custom-tree-container">
                <div class="block">
                  <ul>
                    <li
                      class="li-task"
                      v-for="(task, j) in level.Tasks"
                      :key="j"
                    >
                      <span>
                        <span
                          v-if="task.test_id != null"
                          style="color: red; font-weight: 600; margin-right: 10px;"
                          >[ТЕСТ]</span
                        >
                        {{
                          task.test_id != null
                            ? ` ${task.QuizName} [ID: ${task.test_id}]`
                            : task.name
                        }}</span
                      >
                      <span class="btn-levels" @click="openEditTask(task)">
                        Редактировать
                      </span>

                      <ul>
                        <li v-for="(material, k) in task.Materials" :key="k">
                          <a
                            :href="
                              fileDownloadProtocolReplace(material.url_file)
                            "
                            >{{
                              material.url_file.slice(
                                Math.max(
                                  material.url_file.lastIndexOf("/"),
                                  material.url_file.lastIndexOf("\\")
                                ) + 15
                              )
                            }}</a
                          >
                          <span class="task-btn">
                            <!-- <a @click="dialogEditMaterial = true">
                              <i class="far fa-edit"></i>
                            </a> -->
                            <a @click="dialogDeleteMaterial(material)">
                              <i class="far fa-trash-alt"></i>
                            </a>
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <!-- <el-tree
                    :data="dataSource"
                    node-key="id"
                    :expand-on-click-node="false"
                    :render-content="renderContent"
                  >
                    <template #default="{ node }">
                      <span class="custom-tree-node">
                        <span>{{ node.label }}</span>
                        <span class="task-btn">
                          <a @click="dialogEditTask = true">
                            <i class="far fa-edit"></i>
                          </a>
                          <a><i class="far fa-trash-alt"></i></a>
                        </span>
                      </span>
                    </template>
                  </el-tree> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ImageCompress from "quill-image-compress";
import { VEUploadImage } from "@/api/visual_editor.js";

import { getLevels, getSections, updateLevel } from "@/api/competence.js";
import { getUserQuizes, getQuizShortInfo } from "@/api/user_tests.js";
import {
  updateTask,
  deleteTask,
  createTask,
  deleteMaterial,
} from "@/api/task.js";

import { mapGetters } from "vuex";

export default {
  name: "AdminLevelPage",
  components: { QuillEditor },
  setup: () => {
    const moduleImageCompress = {
      name: "imageCompress",
      module: ImageCompress,
      options: {
        quality: 0.3,
        maxWidth: 1200,
        maxHeight: 1200,
        imageType: "image/webp", //Не до конца понимаю, за что отвечает данное свойство
        keepImageTypes: ["image/jpeg", "image/png", "image/webp"],
        debug: true,
        insertIntoEditor: (imageBase64URL, imageBlob, editor) => {
          const formData = new FormData();
          formData.append("file", imageBlob);

          VEUploadImage(formData).then((x) => {
            if (x.data == null) {
              alert("Ошибка при загрузке файла");
            } else {
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", `${x.data}`, "user");
            }
          });
        },
      },
    };

    const modules = [moduleImageCompress];

    return { modules };
  },
  data() {
    return {
      options: [],
      levels: [],
      value: null,
      dialogEditTask: false,
      dialogAddTask: false,
      dialogEditLevel: false,
      editLevelForm: {
        id: null,
        name: "",
        description: "",
      },
      addTasksForm: {
        level_id: null,
        name: "",
        description: "",
        materials: [],
        test_id: null,
      },
      editTasksForm: {
        id: null,
        level_id: null,
        name: "",
        description: "",
        materials: [],
        test_id: null,
        materials_existed: [],
      },

      addTaskType: "задача",
      UserTestsList: [],
    };
  },
  async created() {
    let _levels_resp = await getLevels();
    let _levels = _levels_resp.data;
    for (let level of _levels) {
      for (let level_task of level.Tasks) {
        if (level_task.test_id != null) {
          level_task.QuizName = await this.getQuizShortInfoData(
            level_task.test_id
          );
        }
      }
    }
    this.levels = _levels;

    getSections().then((response) => {
      this.options = response.data;
    });
    getUserQuizes(this.getUser.id).then((response) => {
      this.UserTestsList = response.data;
    });
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    getUser() {
      return this?.user;
    },

    filterLevels() {
      if (this.value === null) return [];
      else {
        return this.levels
          .filter((level) => {
            let ans = false;
            this.options
              .find((el) => el.id === this.value)
              .degrees.forEach((element) => {
                element.levels.forEach((item) => {
                  if (item.id === level.id) {
                    ans = true;
                  }
                });
              });
            return ans;
          })
          .sort((a, b) => {
            if (b.degree_id !== a.degree_id) {
              return b.degree_id - a.degree_id;
            } else {
              return a.id - b.id;
            }
          });
      }
    },
  },
  methods: {
    openEditLevel(level) {
      this.editLevelForm.id = level.id;
      this.editLevelForm.name = level.name;
      this.editLevelForm.description = level.description;
      this.dialogEditLevel = true;
    },
    editLevel() {
      updateLevel(this.editLevelForm.id, this.editLevelForm)
        .then(() => {
          getLevels().then((response) => {
            this.levels = response.data;
          });
          this.dialogEditLevel = false;
          this.$message({
            type: "success",
            message: `Уровень обновлен!`,
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: `Произошла ошибка`,
          });
        });
    },
    addTask() {
      const formData = new FormData();
      if (this.addTasksForm.materials.length > 0) {
        for (let material of this.addTasksForm.materials) {
          formData.append("files[]", material.file, material.name);
        }
      } else {
        formData.append("files", null);
      }
      for (let key in this.addTasksForm) {
        if (
          key !== "id" &&
          key !== "materials" &&
          this.addTasksForm[key] !== null
        ) {
          formData.append(key, this.addTasksForm[key]);
        }
      }
      createTask(formData)
        .then(() => {
          getLevels().then((response) => {
            this.levels = response.data;
          });
          this.dialogAddTask = false;
          this.$message({
            type: "success",
            message: `Задача создана!`,
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: `Произошла ошибка`,
          });
        });
    },
    deleteTask() {
      this.$confirm("Вы точно хотите удалить задачу?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          deleteTask(this.editTasksForm.id)
            .then(() => {
              getLevels().then((response) => {
                this.levels = response.data;
              });
              this.dialogEditTask = false;
              this.$message({
                type: "success",
                message: "Delete completed",
              });
            })
            .catch(() => {
              this.$message({
                type: "error",
                message: `Произошла ошибка`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    editTask() {
      const formData = new FormData();

      if (this.addTaskType == "задача") {
        this.editTasksForm.test_id = null;
      }

      formData.append("test_id", JSON.stringify(this.editTasksForm.test_id));

      let materials_filtered = this.editTasksForm.materials.filter(
        (x) => x.url != null
      );

      let materials_for_delete = this.editTasksForm.materials_existed
        .filter(
          (item) =>
            !this.editTasksForm.materials.find(
              (element) =>
                element.name === item.name && element.url === item.url
            )
        )
        .map((x) => x?.id)
        .filter((x) => x != null);
      formData.append(
        "materials_for_delete",
        JSON.stringify(materials_for_delete)
      );

      if (materials_filtered.length > 0) {
        for (let material of materials_filtered) {
          formData.append("files[]", material.file, material.name);
        }
      } else {
        formData.append("files", null);
      }
      for (let key in this.editTasksForm) {
        if (key !== "id" && key !== "materials" && key !== "test_id") {
          formData.append(key, this.editTasksForm[key]);
        }
      }

      updateTask(this.editTasksForm.id, formData)
        .then(() => {
          getLevels().then((response) => {
            this.levels = response.data;
          });
          this.dialogEditTask = false;
          this.$message({
            type: "success",
            message: `Задача обновлена!`,
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: `Произошла ошибка`,
          });
        });
    },
    openAddTasks(level) {
      this.addTasksForm = {
        level_id: level.id,
        name: "",
        description: "",
        materials: [],
        test_id: null,
      };
      this.dialogAddTask = true;
    },
    openEditTask(task) {
      this.editTasksForm.id = task.id;
      this.editTasksForm.level_id = task.level_id;
      this.editTasksForm.name = task.name;
      this.editTasksForm.description = task.description;
      this.editTasksForm.test_id = task?.test_id;
      this.dialogEditTask = true;

      this.editTasksForm.materials = task.Materials.map((x) => {
        // Пример адреса файла
        const filePath = x.url_file;

        // Функция для извлечения имени файла с расширением без чисел до символа "-"
        let getCleanFileNameWithExtension = (filePath) => {
          const parts = filePath.split(/[\\\/]/);
          const fileNameWithExtension = parts[parts.length - 1];
          const fileNameWithoutNumbersBeforeDash = fileNameWithExtension.replace(
            /^\d+-/,
            ""
          );
          return fileNameWithoutNumbersBeforeDash;
        };

        const cleanFileNameWithExtension = getCleanFileNameWithExtension(
          filePath
        );
        return {
          id: x.id,
          name: cleanFileNameWithExtension.trim(),
          url: null,
          file: new File(["0"], cleanFileNameWithExtension.trim(), {
            type: "text/plain",
          }),
        };
      });

      this.editTasksForm.materials_existed = [...this.editTasksForm.materials];
    },
    dialogDeleteMaterial(material) {
      this.$confirm("Вы точно хотите удалить материал?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          deleteMaterial(material.id)
            .then(() => {
              getLevels().then((response) => {
                this.levels = response.data;
              });
              this.$message({
                type: "success",
                message: "Delete completed",
              });
            })
            .catch(() => {
              this.$message({
                type: "error",
                message: `Произошла ошибка`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    handleAddRemove(file, fileList) {
      this.addTasksForm.materials = this.addTasksForm.materials.filter(
        (elem) => elem.url !== file.url
      );
    },
    handleAddMaterialSuccess(file) {
      // console.log(file)
      this.addTasksForm.materials.push({
        url: URL.createObjectURL(file.raw),
        file: file.raw,
        name: file.name,
      });
    },
    handleEditRemove(file, fileList) {
      this.editTasksForm.materials = fileList;
    },
    handleEditMaterialSuccess(file) {
      // console.log(file)
      this.editTasksForm.materials.push({
        url: URL.createObjectURL(file.raw),
        file: file.raw,
        name: file.name,
      });
    },

    async getQuizShortInfoData(id) {
      let _resp = await getQuizShortInfo(id);

      return `${_resp?.data?.name} (${_resp?.data?.description})`;
    },

    handleEditorEditChanges(delta) {
      this.editTasksForm.description = this.$refs.QuillEditor.getHTML();
    },
    handleEditorEditReady() {
      this.$refs.QuillEditor.setHTML(this.editTasksForm.description);
    },
    handleEditorAddChanges(delta) {
      this.addTasksForm.description = this.$refs.QuillEditorAdd.getHTML();
    },
    handleEditorAddReady() {
      this.$refs.QuillEditorAdd.setHTML(this.addTasksForm.description);
    },

    fileDownloadProtocolReplace(url) {
      if (url === "" || url == null) {
        return "#";
      }

      let hasAnyProtocol = url.substring(0, 4); //http

      if (hasAnyProtocol === "http") {
        if (url.substring(0, 5) === "https") {
          return url;
        } else {
          return url.replace("http", "https");
        }
      } else {
        return `https://${url}`;
      }
    },
  },
};
</script>

<style scoped>
ul {
  display: flex;
  list-style: none outside;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0px;
}
li {
  text-align: left;
  padding: 0px 0px;
}
.li-task {
  display: flex;
}
.btn-levels {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin-left: 10px;
  font-size: 0.9em;
  display: flex;
  background-color: white;
  color: black;
  padding: 2px 10px;
  border-radius: 10px;
}
.btn-levels:hover {
  box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.362);
}

.demo-input-label {
  margin-bottom: 10px;
}
.level-task {
  margin-top: 5px;
  width: 100% !important;
}
.custom-tree-node {
  width: 100%;
  justify-content: space-between;
  display: flex;
  color: black;
}

.level-info {
  width: 100%;
}

.level-header {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

i {
  color: black;
}

.level-btn > button {
  border: 1px solid #c5bfbf;
  padding: 2px 13px;
  margin: 5px 5px;
  border-radius: 10px;
  background-color: white;
  /* box-shadow: 2px 5px 10px rgba(138, 138, 138, 0.5); */
}

.level-btn > button:hover {
  box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.362);
}
.level-btn {
  display: flex;
}

.level-desc {
  margin: 5px 0px;
  display: flex;
}

.level-name {
  display: flex;
  font-size: 1.3em;
  font-weight: 600;
  width: 100%;
}

.el-select {
  padding: 0px 20px;
  width: 60%;
}

.tags-p {
  display: flex;
  width: 20%;
}

.level-add {
  background-color: #d64a4a;
  color: white;
  width: 20%;
  border: none;
  padding: 5px;
  border-radius: 10px;
}

.level-add:hover {
  opacity: 0.9;
}

.card-level {
  text-align: start;
  margin: 20px 0px;
  padding: 30px 40px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 2px 5px 20px rgba(138, 138, 138, 0.5);
  font-family: "Montserrat" !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 10px !important;
}

.container {
  color: white;
  font-family: "Montserrat";
  padding: 100px 100px;
}
.admin-level {
  min-height: 100vh;
  background-position: 100% 135% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: 30% !important;
  z-index: 1000;
}

h2 {
  display: flex;
  font-weight: 600;
}

.select-directions {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  width: 100%;
}

.LevelTypeForm {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.LevelTypeForm > label {
  margin-left: 4px;
  font-size: 16px;
  color: white;
}

.QEditor {
  position: relative;
  width: 100%;
  height: 600px;
  padding-bottom: 100px;
}
</style>
