<template>
  <div class="welcome">
    <div class="container">
      <el-row>
        <div>
          <p class="header-welcome">Управление проектами</p>
        </div>
      </el-row>

      <div class="manage-project-toolbar">
        <div>
          <el-select
            v-model="value"
            multiple
            filterable
            placeholder="Выберите направления для фильтрации"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <el-button type="primary" @click="dialogFormVisible = true"
          >Добавать проект</el-button
        >

        <el-button type="warning" @click="dialogDirectVisible = true"
          >Добавить направление</el-button
        >
      </div>

      <el-row>
        <div class="step2">
          <el-scrollbar height="500px">
            <div v-for="(project, i) in filterProjects" :key="i">
              <div class="project-card">
                <el-button class="read-more" @click="openEditProject(project)">
                  <i class="fas fa-edit"></i>
                  <p class="choose">
                    Изменить <br />
                    проект
                  </p>
                </el-button>
                <div class="project-info">
                  <p class="project-name">
                    {{ project.name }}
                    <span
                      :style="
                        `color: ${
                          project.archieve ? 'rgb(235, 87, 87)' : 'transparent'
                        };`
                      "
                      >{{ project.archieve ? "(Архивировано)" : "" }}</span
                    >
                  </p>
                  <div class="project-info-desc">
                    <div
                      class="ql-snow"
                      style="position: relative; display: block; width: 100%; height: fit-content;"
                    >
                      <span
                        style="position: relative; display: block; width: 100%; height: fit-content; text-align: left;"
                        >Описание:
                      </span>
                      <span
                        class="ql-editor"
                        style="position: relative; display: block; width: 100%; height: fit-content;"
                        v-html="project.description"
                      >
                      </span>
                    </div>

                    <span class="social-project" v-if="project?.isSocial == 1">
                      <span class="social-project-icon"></span>
                      <span class="social-project-text">Социальный проект</span>
                    </span>

                    <!-- <div class="project-badges">
                      Направления:
                      <span
                        class="badge badge-default"
                        :style="'background-color: ' + tag.color + '!important'"
                        v-for="tag in project.Directions"
                        :key="tag.id"
                        >{{ tag.name }}</span
                      >
                    </div> -->
                  </div>
                </div>
                <div class="project-img">
                  <img v-if="project.url_img" :src="project.url_img" />
                  <img v-else src="@/assets/img/telega.jpg" />
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </el-row>
    </div>

    <el-dialog
      title="Добавление проекта"
      modal-class="DontUseDefaultRestyleColor"
      custom-class="DontUseDefaultRestyleColor"
      v-model="dialogFormVisible"
      width="35%"
      center
    >
      <el-form :model="form">
        <el-form-item label="Название" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Описание" :label-width="formLabelWidth">
          <div
            class="QEditor QEditorFixHeight"
            style="position: relative; width: auto; height: 400px;"
          >
            <QuillEditor
              ref="QuillEditorADD"
              :modules="modules"
              toolbar="full"
              theme="snow"
              contentType="html"
              :content="form.desc"
              @textChange="
                handleEditorEditChanges($event, 'ADD', 'QuillEditorADD')
              "
              @ready="handleEditorEditReady('ADD', 'QuillEditorADD')"
            />
          </div>
        </el-form-item>
        <!-- <el-form-item label="Тип проекта" :label-width="formLabelWidth">
          <el-select
            v-model="form.project_type"
            filterable
            placeholder="Выберите тип проекта"
          >
            <el-option
              v-for="p_type in project_types"
              :key="p_type.value"
              :label="p_type.value"
              :value="p_type.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="Для студентов" :label-width="formLabelWidth">
          <el-switch v-model="form.student"></el-switch>
        </el-form-item>
        <el-form-item label="Социальный проект" :label-width="formLabelWidth">
          <el-switch v-model="form.isSocial"></el-switch>
        </el-form-item> -->
        <!-- <el-form-item label="Направления" :label-width="formLabelWidth">
          <el-select
            v-model="form.type"
            filterable
            multiple
            placeholder="Выберите направления"
          >
            <el-option
              v-for="createLevel in options"
              :key="createLevel.id"
              :label="createLevel.name"
              :value="createLevel.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <CompetenceProject @select-level="selectLevel" /> -->
        <div class="change-proj-modal-photo">
          <div class="change-proj-modal-photo-title">Изображение проекта</div>
          <el-upload
            class="avatar-uploader"
            action="#"
            :auto-upload="false"
            :show-file-list="false"
            :on-change="handleImageSuccess"
          >
            <img v-if="form.img_url" :src="form.img_url" class="avatar" />
            <img
              v-else
              class="img-card"
              src="../../assets/img/profile/user_icon.png"
            />
            <div class="el-upload__text">Загрузить объект</div>
          </el-upload>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Отменить</el-button>
          <el-button type="primary" @click="addProject">Сохранить</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="Изменение проекта"
      modal-class="DontUseDefaultRestyleColor"
      custom-class="DontUseDefaultRestyleColor"
      v-model="dialogEditVisible"
      width="35%"
      center
    >
      <el-form :model="formEdit">
        <el-form-item label="Архив" :label-width="formLabelWidth">
          <div class="checkbox_agreement">
            <input
              type="checkbox"
              id="advert_checkbox"
              name="advert_checkbox"
              v-model="formEdit.archieve"
              style="width: 24.4px !important;"
            />
          </div>
        </el-form-item>
        <el-form-item label="Название" :label-width="formLabelWidth">
          <el-input v-model="formEdit.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Описание" :label-width="formLabelWidth">
          <div
            class="QEditor QEditorFixHeight"
            style="position: relative; width: auto; height: 400px;"
          >
            <QuillEditor
              ref="QuillEditorAJD"
              :modules="modules"
              toolbar="full"
              theme="snow"
              contentType="html"
              :content="formEdit.desc"
              @textChange="
                handleEditorEditChanges($event, 'AJD', 'QuillEditorAJD')
              "
              @ready="handleEditorEditReady('AJD', 'QuillEditorAJD')"
            />
          </div>
        </el-form-item>
        <!-- <el-form-item label="Тип проекта" :label-width="formLabelWidth">
          <el-select
            v-model="formEdit.project_type"
            filterable
            placeholder="Выберите тип проекта"
          >
            <el-option
              v-for="p_type in project_types"
              :key="p_type.value"
              :label="p_type.value"
              :value="p_type.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="Для студентов" :label-width="formLabelWidth">
          <el-switch v-model="formEdit.student"></el-switch>
        </el-form-item>
        <el-form-item label="Социальный проект" :label-width="formLabelWidth">
          <el-switch v-model="formEdit.isSocial"></el-switch>
        </el-form-item> -->
        <!-- <el-form-item label="Направления" :label-width="formLabelWidth">
          <el-select
            v-model="formEdit.type"
            filterable
            multiple
            placeholder="Выберите направления"
          >
            <el-option
              v-for="editLevel in options"
              :key="editLevel.id"
              :label="editLevel.name"
              :value="editLevel.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="dialogEditVisible">
          <CompetenceProject
            @select-level="selectLevelEdit"
            :levelshow="formEdit.level"
          />
        </template> -->
        <div class="change-proj-modal-photo">
          <div
            class="change-proj-modal-photo-title"
            style="color: #e8e7ec !important;"
          >
            Изображение проекта
          </div>
          <el-upload
            class="avatar-uploader"
            action="#"
            :auto-upload="false"
            :show-file-list="false"
            :on-change="handleImageEditSuccess"
          >
            <img
              v-if="formEdit.img_url"
              :src="formEdit.img_url"
              class="avatar"
            />
            <img
              v-else
              class="img-card"
              src="../../assets/img/profile/user_icon.png"
            />
            <div class="el-upload__text" style="color: #e8e7ec !important;">
              Загрузить объект
            </div>
          </el-upload>
        </div>
      </el-form>

      <template #footer>
        <div
          style="position: relative; display: flex; flex-direction: column; flex-wrap: wrap; justify-content: center; gap: 20px; width: 100%; height: fit-content;"
        >
          <el-button
            style="margin: 0 !important;"
            type="primary"
            @click="editProject"
            >Сохранить</el-button
          >
          <el-button
            style="margin: 0 !important;"
            @click="dialogEditVisible = false"
            >Отменить</el-button
          >
          <el-button
            style="margin: 0 !important;"
            type="warning"
            @click="deleteProject"
            >Удалить</el-button
          >
        </div>
      </template>
    </el-dialog>

    <el-dialog
      title="Добавление направления"
      v-model="dialogDirectVisible"
      width="35%"
      center
    >
      <el-form :model="formDirection">
        <el-form-item label="Название" :label-width="formLabelWidth">
          <el-input v-model="formDirection.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Цвет" :label-width="formLabelWidth">
          <el-color-picker
            v-model="formDirection.color"
            show-alpha
          ></el-color-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDirectVisible = false">Отменить</el-button>
          <el-button type="primary" @click="addDirection">Сохранить</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { h } from "vue";
import { ElNotification } from "element-plus";

import {
  updateProject,
  findAllAdmin,
  createProject,
  deleteProject,
} from "@/api/project.js";
import { getAllDirection, createDirection } from "@/api/direction.js";
import CompetenceProject from "@/views/Competence/competence-project";

import { QuillEditor } from "@vueup/vue-quill";

import ImageCompress from "quill-image-compress";
import { VEUploadImage } from "@/api/visual_editor.js";

export default {
  name: "AdminProject",
  components: { CompetenceProject, QuillEditor },
  setup: () => {
    const moduleImageCompress = {
      name: "imageCompress",
      module: ImageCompress,
      options: {
        quality: 0.8,
        maxWidth: 10000, // default
        maxHeight: 10000, // default
        imageType: "image/webp",
        keepImageTypes: ["image/jpeg", "image/png", "image/webp"],
        debug: false,
        insertIntoEditor: (imageBase64URL, imageBlob, editor) => {
          const formData = new FormData();
          formData.append("file", imageBlob);

          VEUploadImage(formData).then((x) => {
            if (x.data == null) {
              alert("Ошибка при загрузке файла");
            } else {
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", `${x.data}`, "user");
            }
          });
        },
      },
    };

    const modules = [moduleImageCompress];

    return { modules };
  },
  data() {
    return {
      project_types: [
        { value: "Идея" },
        { value: "Продукт" },
        { value: "Проект" },
      ],
      projects: [],
      options: [],
      value: [],
      dialogFormVisible: false,
      dialogEditVisible: false,
      dialogDirectVisible: false,
      formEdit: {
        project_type: "",
        id: "",
        name: "",
        type: [],
        level: [],
        desc: "",
        student: true,
        isSocial: false,
        img: null,
        img_url: "",
        archieve: false,
      },
      formDirection: {
        name: "",
        color: "",
      },
      form: {
        project_type: "",
        name: "",
        type: [],
        level: [],
        desc: "",
        student: true,
        isSocial: false,
        img: null,
        img_url: "",
      },
      formLabelWidth: "150px",
    };
  },
  created() {
    findAllAdmin().then((response) => {
      this.projects = response.data;
    });

    getAllDirection().then((response) => {
      this.options = response.data;
    });
  },
  computed: {
    filterProjects() {
      if (this.value.length === 0) return this.projects;
      else {
        return this.projects.filter(
          (project) =>
            this.intersection(
              new Set(project.Directions.map((elem) => elem.id)),
              new Set(this.value)
            ).size > 0
        );
      }
    },
  },
  methods: {
    handleEditorEditChanges(delta, setTo, fromEditorName) {
      let htmlDataFromEditor = this.$refs[fromEditorName].getHTML();
      switch (setTo) {
        case "AJD": {
          this.formEdit.desc = htmlDataFromEditor;
          break;
        }
        case "ADD": {
          this.form.desc = htmlDataFromEditor;
          break;
        }
      }
    },
    handleEditorEditReady(setTo, fromEditorName) {
      let dataToSet = "";
      switch (setTo) {
        case "AJD": {
          dataToSet = this.formEdit.desc;
          break;
        }
        case "ADD": {
          dataToSet = this.form.desc;
          break;
        }
      }
      this.$refs[fromEditorName].setHTML(dataToSet);
    },

    selectLevelEdit(levels) {
      this.formEdit.level = levels;
    },
    selectLevel(levels) {
      this.form.level = levels;
    },
    addProject() {
      const formData = new FormData();
      if (this.form.img) {
        formData.append("file", this.form.img, this.form.img.name);
      } else {
        formData.append("file", null);
      }
      for (let key in this.form) {
        if (key !== "img" && key !== "img_url" && this.form[key] !== null) {
          formData.append(key, JSON.stringify(this.form[key]));
        }
      }
      createProject(formData)
        .then(() => {
          findAllAdmin().then((response) => {
            this.projects = response.data;
          });
          this.dialogFormVisible = false;
          this.$message({
            type: "success",
            message: `Проект создан!`,
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: `Произошла ошибка`,
          });
        });
    },
    editProject() {
      const formData = new FormData();

      console.log(this.formEdit.img);
      if (this.formEdit.img) {
        formData.append("file", this.formEdit.img, this.formEdit.img.name);
      } else {
        formData.append("file", null);
      }
      for (let key in this.formEdit) {
        if (
          key !== "id" &&
          key !== "img" &&
          key !== "img_url" &&
          this.formEdit[key] !== null
        ) {
          formData.append(key, JSON.stringify(this.formEdit[key]));
        }
      }
      updateProject(this.formEdit.id, formData)
        .then((res) => {
          findAllAdmin().then((response) => {
            this.projects = response.data;
          });
          this.dialogEditVisible = false;
          this.$message({
            type: "success",
            message: `Проект обновлен!`,
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: `Произошла ошибка`,
          });
        });
    },
    deleteProject() {
      this.$confirm("Вы точно хотите удалить проект?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          deleteProject(this.formEdit.id)
            .then(() => {
              findAllAdmin().then((response) => {
                this.projects = response.data;
              });
              this.dialogEditVisible = false;
              this.$message({
                type: "success",
                message: "Delete completed",
              });
            })
            .catch(() => {
              this.$message({
                type: "error",
                message: `Произошла ошибка`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    openEditProject(project) {
      console.log(project);
      this.formEdit.id = project.id;
      this.formEdit.name = project.name;
      this.formEdit.desc = project.description;
      this.formEdit.student = project.isStudents;
      this.formEdit.isSocial = project.isSocial;
      this.formEdit.img_url = project.url_img;
      this.formEdit.archieve = project.archieve;
      // this.formEdit.type = project.Directions.map((item) => item.id);
      // this.formEdit.level = project.Levels.map((item) => item.id);
      this.dialogEditVisible = true;
    },
    addDirection() {
      createDirection(this.formDirection)
        .then(() => {
          getAllDirection().then((response) => {
            this.options = response.data;
          });
          this.dialogDirectVisible = false;
          this.$message({
            type: "success",
            message: `Направление создано!`,
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: `Произошла ошибка`,
          });
        });
    },
    handleImageSuccess(file) {
      if (
        file.raw.type != "image/jpeg" &&
        file.raw.type != "image/png" &&
        file.raw.type != "image/jpg"
      ) {
        ElNotification({
          title: "Ошибка!",
          message: h(
            "i",
            { style: "color: green; z-index: 99999;" },
            "Можно загружать только .png, .jpeg и .jpg файлы!"
          ),
          type: "error",
          position: "top-right",
        });
        return;
      }
      this.form.img = file.raw;
      this.form.img_url = URL.createObjectURL(file.raw);
    },
    handleImageEditSuccess(file) {
      if (
        file.raw.type != "image/jpeg" &&
        file.raw.type != "image/png" &&
        file.raw.type != "image/jpg"
      ) {
        ElNotification({
          title: "Ошибка!",
          message: h(
            "i",
            { style: "color: green; z-index: 99999;" },
            "Можно загружать только .png, .jpeg и .jpg файлы!"
          ),
          type: "error",
          position: "top-right",
        });
        return;
      }

      this.formEdit.img = file.raw;
      this.formEdit.img_url = URL.createObjectURL(file.raw);
    },
    intersection(setA, setB) {
      var _intersection = new Set();
      for (var elem of setB) {
        if (setA.has(elem)) {
          _intersection.add(elem);
        }
      }
      return _intersection;
    },
  },
};
</script>

<style>
.el-select-dropdown .el-select-dropdown__item {
  color: white !important ;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: #acacac !important;
}

.el-input .el-input--suffix .is-focus {
  border: 1px solid #acacac !important;
  outline: none !important;
}

.el-tag {
  background-color: #dfdfdf !important;
  color: black !important;
  font-weight: 500;
}
.el-tag > i {
  color: black !important;
  /* background-color: #212529 !important; */
}
</style>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.project-info-desc > .ql-snow {
  color: #fff;
}

.social-project {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;

  width: fit-content;
  height: fit-content;

  margin-top: auto;
  margin-bottom: 26px;
}
.social-project > * {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}

.social-project-icon {
  display: block;
  width: 34px;
  height: 34px;
  background: url("./../../assets/img/profile/ProfileProjectCard/Social.svg");

  margin-right: 10px;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.social-project-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;

  /* or 125% */

  color: #ffffff;
}

.manage-project-toolbar {
  display: flex;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
}

.manage-project-toolbar div {
  width: 80%;
}

.manage-project-toolbar div .el-select {
  width: 100%;
}

.manage-project-toolbar .el-button {
  width: 20%;
  margin: 0px 0px 0px 10px;
  height: 20px;
  font-size: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-proj-modal-photo {
  width: 100%;
  display: flex;
  margin-top: 50px;
}

.change-proj-modal-photo-title {
  width: 40%;
}

.change-proj-modal-photo .avatar-uploader {
  width: 60%;
  display: flex;
  justify-content: center;
}

.change-proj-modal-photo .avatar-uploader img {
  width: 80%;
  border-radius: 20px;
}

.header-welcome {
  margin-top: 40px;
}

/* ---------------------------------------- old css */

.step3 {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.chart-container {
  padding-top: 10px !important;
}
.el-select {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px !important;
}

.chosen {
  background-color: rgb(18, 160, 77) !important;
  /* opacity: 0.7; */
  color: white !important;
}

.project-card:hover {
  /* opacity: 0.7; */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2) !important;
}

.read-more:hover {
  background-color: #d64a4a !important;
  color: #fff !important;
}
.read-more {
  width: 130px;
  background-color: #811616 !important;
  color: #606266 !important;
  cursor: pointer !important;
  font-family: "Montserrat";
  border-radius: 15px 0px 0px 15px;
  border: none;
  border-right: 1px solid #e4e4e4 !important;
}

.choose {
  margin-top: 10px;
}

.badge {
  margin-bottom: 5px !important;
  font-size: 0.7rem;
  font-weight: 600;
  background-color: #d64a4a !important;
  margin-left: 5px;
}

.project-badges {
  font-size: 0.75rem;
  font-weight: 600;
  color: #3f3f3f;
}

.project-info-desc {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  overflow: auto;
}
.project-name {
  text-align: start;
  font-weight: 600;
  font-size: 1rem;
  color: #212529;
}

.project-desc {
  text-align: start;
  font-size: 0.9rem;
  color: #222324;
}

.el-badge {
  background-color: transparent !important;
}

.sup {
  background-color: #212529;
}

.project-info {
  padding: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.project-img {
  width: 20%;
  display: flex;
}

.project-img > img {
  object-fit: cover;
  width: 100%;
  border-radius: 0px 15px 15px 0px !important;
  opacity: 0.8;
}

.project-card {
  display: flex;
  margin: 0px 20px 25px 0px;
  height: 350px;
  box-shadow: none !important;
  border: 1px solid #d4d4d4 !important;
}

.el-scrollbar {
  width: 100%;
  border-radius: 0px !important;
}

.step2 {
  background-color: transparent !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.next-btn {
  border-radius: 15px;
  padding: 9px;
  width: 12rem;
  font-size: 0.9rem;
  background-color: #d64a4a !important;
  color: #fff !important;
  border: none !important;
  margin-top: 20px;
}

.next-btn:hover {
  background-color: #d64a4a !important;
  opacity: 0.9 !important;
  color: #fff !important;
  border: none !important;
}
.step-desc {
  font-weight: 600;
  color: black;
}
.stepper {
  margin: 20px 0px;
  display: flex;
  min-height: 50vh;
  width: 100%;
}
.steps {
  width: 10%;
  margin-right: 80px;
}

.user-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  width: 60%;
  justify-content: space-between;
  display: flex;
  align-items: baseline;
}

.user-field {
  color: black;
  border: 1px solid #bdbdbd;
  border-radius: 14px !important;
  padding: 10px;
}

.user-field:focus-visible {
  outline: none !important;
}

.step1 {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
}

.container {
  width: 70%;
}

.header-welcome {
  text-align: start;
  font-size: 2rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.welcome {
  position: relative;
  margin-top: 60px;

  font-family: "Montserrat";
  min-height: 100vh;
  background-image: url("../../assets/img/_landing/karakul2.svg") !important;
  background-position: 100% 135% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  z-index: 0;
}
</style>

<style scoped>
.checkbox_agreement {
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.3rem;
  align-items: center;
}

.checkbox_agreement a {
  font-weight: 500;
  color: #6c59f7;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #6e6a9a;
  border-radius: 4px;
  padding: 0.7rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-right: 1rem;
}

input[type="checkbox"]:checked {
  background-image: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>
