<template>
  <div class="checking">
    <div class="container">
      <h2>Проверка задач</h2>
      <div class="direction">
        <div class="tags-p">Выбранное направление</div>
        <el-select
          v-model="value"
          filterable
          placeholder="Выберите направление обучения"
          @change="updateSolutions"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="ReadyTasks">
        <el-button type="primary" @click="ReadyTasksDialog = true"
          >Принятые задачи</el-button
        >
      </div>

      <el-dialog v-model="dialogCheck" title="Проверить задачу" width="80%">
        <div>
          <div class="demo-input-suffix">
            <div
              style="position: relative; display: flex; flex-direction: column; gap: 10px;"
            >
              <span class="demo-input-label">Комментарий</span>
              <div class="QEditor" style="position: relative; ">
                <QuillEditor
                  ref="QuillEditor"
                  :modules="modules"
                  toolbar="full"
                  theme="snow"
                  contentType="html"
                  :content="editSolutionForm.comment"
                  @textChange="handleEditorEditChanges"
                  @ready="handleEditorEditReady"
                  :key="
                    'EditorUpdateKey' +
                      editSolutionForm.id +
                      editSolutionForm.level_id +
                      editSolutionForm.user_id
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              @click="
                () => {
                  dialogCheck = false;
                }
              "
              >Отменить</el-button
            >
            <el-button type="success" @click="editSolutions(true)"
              >Принять задачу</el-button
            >
            <el-button
              type="danger"
              :style="
                editSolutionForm.comment != '' &&
                editSolutionForm.comment != null &&
                editSolutionForm.comment != '<p><br></p>'
                  ? ''
                  : 'opacity: 0.5;'
              "
              @click="
                () => {
                  if (
                    editSolutionForm.comment != '' &&
                    editSolutionForm.comment != null &&
                    editSolutionForm.comment != '<p><br></p>'
                  )
                    editSolutions(false);
                }
              "
              >Отклонить задачу</el-button
            >
          </span>
        </template>
        <UserTaskHistory
          :user="editSolutionForm.user_id"
          :level="editSolutionForm.level_id"
          v-bind:taskName="true"
          :key="
            'UserTaskHistory' +
              editSolutionForm.id +
              editSolutionForm.level_id +
              editSolutionForm.user_id
          "
        ></UserTaskHistory>
      </el-dialog>
      <!-- <el-dialog
        v-model="dialogExternCheck"
        title="Проверить задачу"
        width="40%"
      >
        <div>
          <div class="mb-3">
            <div class="externDesc">
              Текущий уровень студента:
              {{ editExternForm.studentLevels }}
            </div>
            <div class="demo-rate-block">
              <span class="externRate mb-3"
                >Какой уровень установить студенту?</span
              >
              <el-rate
                class="externRate"
                show-score
                score-template="{value} уровень"
                size="large"
                :max="editExternForm.sectionLevels"
                v-model="editExternForm.newStudentLevels"
              />
            </div>
          </div>
          <div class="demo-input-suffix">
            <el-row :gutter="20">
              <span class="demo-input-label mb-2">Комментарий</span>
              <el-input
                v-model="editExternForm.comment"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
                placeholder="Введите комментарий проверки"
              >
              </el-input>
            </el-row>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogExternCheck = false">Отменить</el-button>
            <el-button type="success" @click="editExternSolution(true)"
              >Принять задачу</el-button
            >
            <el-button type="danger" @click="editExternSolution(false)"
              >Отклонить задачу</el-button
            >
          </span>
        </template>
      </el-dialog> -->
      <!-- <div v-if="filterExterns.length > 0" class="header-type-sol">
        <p>
          Заявки на получение уровней
          <span style="color: #d64a4a">экстерном</span>
        </p>
      </div> -->
      <!-- <div
        class="extern-type task-card"
        v-for="(solution, i) in filterExterns"
        :key="i"
      >
        <div class="name-user">
          Практикант: {{ solution.user.surname }} {{ solution.user.name }}
        </div>
        <div class="name-user">
          Комментарий практиканта: {{ solution.description }}
        </div>
        <div class="name-file">
          Файл:
          <a :href="solution.url_file">{{
            solution.url_file.slice(
              Math.max(
                solution.url_file.lastIndexOf("/"),
                solution.url_file.lastIndexOf("\\")
              ) + 15
            )
          }}</a>
        </div>
        <button class="check-btn" @click="openEditExtern(solution)">
          Проверить
        </button>
      </div> -->
      <div v-if="filterSolutions.length > 0" class="header-type-sol">
        <p>Заявки на обычное получение уровней</p>
      </div>

      <SortingWithFiltersPreset
        class="HideSearchPanel"
        :title="''"
        :originalArray="tasks_solutions"
        :searchAttribures="filters.searchInProperties"
        :setNewArrayCallback="handleSearchFilterSortingObject"
        :sortMethodCallback="sortingMethods"
        :filtersButtons="filters.filtersButtons"
        :sortOptions="filters.sortingOptions"
        :filterButtonModeServer="true"
        :filterButtonParentFilter="() => {}"
        :setFiltersData="{
          button: filters.selectedButton,
          search: filters.selectedSearch,
          sorting: filters.selectedSorting,
        }"
        :postFiltersDataChanges="handleFilterChanges"
        :key="'SortingFiltersSearch-' + products_hash + tasks_solutions"
      />

      <div
        class="task-card "
        v-for="(solution, i) in tasks_filtered"
        :key="
          `task-${i}-${solution.task_id}-${solution.id}_${products_filtered_hash}`
        "
      >
        <div class="name-level">Уровень: {{ solution.task.level.name }}</div>
        <!-- Тут полоска компетенций -->
        <Competence
          :header="false"
          :viewMode="true"
          :competenceWhiteList="getActiveComptenceAsArray"
          :userLevels="[solution.task.level_id]"
          :key="`${i}${solution.task_id}${solution.id}`"
        />
        <hr />
        <div class="name-task">
          Дата сдачи: {{ getHumanDate(solution.createdAt) }}
        </div>
        <div class="name-task">Задача: {{ solution.task.name }}</div>
        <div class="name-task">Описание задачи: {{ solution.task.name }}</div>
        <div class="name-user ql-snow">
          Комментарий практиканта:
          <span class="ql-editor" v-html="solution.description"></span>
        </div>
        <div class="card-username">
          <span>
            <span class="static-text">Практикант:</span>
            <span class="static-text-username">
              {{ solution.user.surname }} {{ solution.user.name }}</span
            >
          </span>
        </div>
        <div class="name-file">
          Файл:
          <a :href="solution.url_file">{{
            solution.url_file.slice(
              Math.max(
                solution.url_file.lastIndexOf("/"),
                solution.url_file.lastIndexOf("\\")
              ) + 15
            )
          }}</a>
          <!-- <button class="download-btn">
            <i class="fas fa-file-download"></i>
          </button> -->
        </div>
        <button class="check-btn" @click="openEditSolutions(solution)">
          Проверить
        </button>
      </div>
    </div>

    <el-dialog v-model="ReadyTasksDialog" title="Принятые задачи" width="30%">
      <div
        v-loading="TasksLoading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        class="ReadyTasksBody"
      >
        <div class="SelectBlock">
          <span class="Title">Направление</span>

          <el-select
            v-model="SDirection"
            class="m-2"
            placeholder="Select"
            size="large"
            @change="getSectionTasks"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>

        <div v-if="SDirection != ''" class="SelectBlock">
          <span class="Title">Задача</span>

          <el-select
            v-model="STask"
            class="m-2"
            placeholder="Select"
            size="large"
            @change="LoadUserReadyTasks()"
          >
            <el-option
              v-for="item in TasksFiltered"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>

        <div class="TasksList">
          <div
            class="Card"
            v-for="item in ReadyUserTasks"
            :key="'Watch_Ready_Tasks-' + ready_user_tasks_hash + item"
          >
            <span class="SubTask">{{ item?.task?.name }}</span>
            <span class="FIO">{{
              `${ReplaceNullToEmptyString(
                item?.user?.name
              )} ${ReplaceNullToEmptyString(
                item?.user?.surname
              )} ${ReplaceNullToEmptyString(item?.user?.patronymic)}`
            }}</span>

            <span class="Date">{{
              `Дата сдачи: ${GetCorrectDate(item?.createdAt)}`
            }}</span>

            <span class="Status">{{ `Статус: ${item?.status?.name}` }}</span>
            <span class="UserComment  ql-snow">
              <span>Комментарий: </span>
              <span class="UCBg ql-editor" v-html="item?.description"></span>
            </span>
            <span class="AdminAnswer ql-snow"
              ><span>Наставник: </span>
              <span class="UCBg ql-editor" v-html="item?.comment"></span
            ></span>
            <a :href="item.url_file">{{ GetFilename(item?.url_file) }}</a>
            <!-- <a
              v-if="item?.status?.name === 'Выполнена'"
              href="#"
              style="margin-top: 10px;"
              @click="
                findAndDeleteSolution(
                  item?.user?.id,
                  item?.task?.level_id,
                  item?.task_id,
                  item?.id
                )
              "
              >Отклонить</a
            > -->
          </div>

          <ButtonGradient
            v-if="!TasksLoading && readyTasks.loadMoreEnable"
            ButtonText="Загрузить ещё"
            @click.native="LoadMoreTask"
          />
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ReadyTasksDialog = false">Закрыть</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Competence from "@/components/Profile/CompetenceBlock.vue";

import UserTaskHistory from "@/components/Edu/UserTaskHistory";

import { getAllSolutions, getAllSolutionsByTask } from "@/api/solution.js";

import { getSections, getSection } from "@/api/competence.js";
import {
  getAllSolutionNotChecked,
  updateSolution,
  getStatuses,
  findAndDelete,
} from "@/api/solution.js";
import { getAllNotCheckedExtern, updateExtern } from "@/api/extern.js";
import { findById } from "@/api/user.js";

import { QuillEditor } from "@vueup/vue-quill";

import ImageCompress from "quill-image-compress";
import { VEUploadImage } from "@/api/visual_editor.js";

import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";

export default {
  name: "CheckTask",
  components: {
    UserTaskHistory,
    Competence,
    QuillEditor,
    SortingWithFiltersPreset,
    ButtonGradient,
  },
  setup: () => {
    const moduleImageCompress = {
      name: "imageCompress",
      module: ImageCompress,
      options: {
        quality: 0.65,
        maxWidth: 1920, // default
        maxHeight: 1920, // default
        imageType: "image/webp",
        keepImageTypes: ["image/jpeg", "image/png", "image/webp"],
        debug: false,
        insertIntoEditor: (imageBase64URL, imageBlob, editor) => {
          const formData = new FormData();
          formData.append("file", imageBlob);

          VEUploadImage(formData).then((x) => {
            if (x.data == null) {
              alert("Ошибка при загрузке файла");
            } else {
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", `${x.data}`, "user");
            }
          });
        },
      },
    };

    const modules = [moduleImageCompress];

    return { modules };
  },
  data() {
    return {
      options: [],
      dialogCheck: false,
      dialogExternCheck: false,
      value: null,
      solutions: [],
      externSolutions: [],
      statuses: [],
      editSolutionForm: {
        id: null,
        level_id: null,
        user_id: null,
        comment: "",
        status_id: 2,
        task_id: null,
        extern_id: null,
      },
      editExternForm: {
        id: null,
        direction_id: null,
        user_id: null,
        comment: "",
        status_id: 2,
        sectionLevels: null,
        studentLevels: null,
        newStudentLevels: null,
      },

      renderTaskHistory: 0,

      ReadyTasksDialog: false,
      TasksLoading: false,
      ReadyTasksListRaw: [],

      Directions: [],
      TasksFiltered: [],
      ReadyUserTasks: [],

      SDirection: "",
      STask: "",

      filters: {
        sortingOptions: [
          {
            text: "Сортировка по умолчанию",
            val: "default",
          },
          {
            text: "Сортировка по сдаче (Старое выше)",
            val: "task_waiting_high",
          },
          {
            text: "Сортировка по сдаче (Новое выше)",
            val: "task_waiting_low",
          },
          {
            text: "Сортировка по студенту (А-Я)",
            val: "student_to_high",
          },
          {
            text: "Сортировка по студенту (Я-А)",
            val: "student_to_low",
          },
          {
            text: "Сортировка по номеру задания (Возрастание)",
            val: "task_to_high",
          },
          {
            text: "Сортировка по номеру задания (Убывание)",
            val: "task_to_low",
          },
        ],
        searchInProperties: ["name", "desc"],
        filtersButtons: [],
        selectedButton: null,
        selectedSorting: "default",
        selectedSearch: "",
      },
      tasks_filtered: [],
      tasks_solutions: [],

      readyTasks: {
        offset: 0,
        counter: 50,
        loadMoreEnable: true,
      },
      sortedLevels: [],
    };
  },
  created() {
    getSections().then((response) => {
      this.options = response.data;

      this.sortedLevels = response.data.map((direction) => {
        let levelsSorted = [];

        /*
          ^Взято из client/src/views/Profile/About.vue, created() -> getSection
          *ChatGPT сделал подсказки по данному коду
        */
        let countL = 1; // Инициализируем счетчик для нумерации уровней

        // Сортируем массив degrees по убыванию id
        direction.degrees.sort((a, b) => b.id - a.id);

        // Проходим по каждому элементу массива degrees
        for (let i = 0; i < direction.degrees.length; i++) {
          // Сортируем массив levels для каждой degree по возрастанию id
          direction.degrees[i].levels.sort((a, b) => a.id - b.id);

          // Проходим по каждому уровню в текущей degree
          for (let j = 0; j < direction.degrees[i].levels.length; j++) {
            // Добавляем уровень в массив levelsSorted и присваиваем ему номер countL
            levelsSorted.push(
              Object.assign(direction.degrees[i].levels[j], {
                level: countL, // Присваиваем номер уровня
              })
            );

            countL++; // Увеличиваем счетчик для следующего уровня
          }
        }
        //Взято из client/src/views/Profile/About.vue, created() -> getSection

        return { id: direction.id, name: direction.name, levels: levelsSorted };
      });
    });
    getStatuses().then((response) => {
      this.statuses = response.data;
    });
    getAllSolutionNotChecked().then((response) => {
      this.solutions = response.data;
    });
    // getAllNotCheckedExtern().then((response) => {
    //   this.externSolutions = response.data;
    // });
  },
  computed: {
    filterSolutions() {
      if (this.value === null) return [];
      else {
        let __sol = this.solutions.filter(
          (solution) => solution.task.level.degree.section_id === this.value
        );
        this.tasks_solutions = __sol;

        return __sol;
      }
    },

    getActiveComptenceAsArray() {
      let a = [];
      a.push(this.value);
      return a;
    },
    // filterExterns() {
    //   if (this.value === null) return [];
    //   else {
    //     return this.externSolutions.filter(
    //       (solution) => solution.direction_id === this.value
    //     );
    //   }
    // },

    products_hash() {
      let a = this.tasks_solutions.map(
        (x) =>
          `${x.id}_${x?.url_file}_${x?.user_id}_${x?.task_id}_${x?.updatedAt}`
      );
      return `${this.value}-${this.tasks_solutions?.length}-${a?.join("-")}`;
    },

    products_filtered_hash() {
      let a = this.tasks_filtered.map(
        (x) =>
          `${x.id}_${x?.url_file}_${x?.user_id}_${x?.task_id}_${x?.updatedAt}`
      );
      return `${this.value}-${this.tasks_filtered?.length}-${a?.join("-")}`;
    },
    ready_user_tasks_hash() {
      let a = this.ReadyUserTasks.map(
        (x) =>
          `${x.id}_${x?.url_file}_${x?.user_id}_${x?.task_id}_${x?.updatedAt}`
      );
      return `${this.TasksLoading}-${this.ReadyUserTasks?.length}-${a?.join(
        "-"
      )}`;
    },
  },
  methods: {
    getHumanDate(dateString) {
      const date = new Date(dateString);

      const day = (date.getDate() < 10 ? "0" : "") + date.getDate();
      const month =
        (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },

    async findAndDeleteSolution(user_id, level_id, task_id, solution_id) {
      if (window.confirm("Вы уверены, что хотите отменить принятое решение?")) {
        await findAndDelete(user_id, level_id, task_id, solution_id);
        let _task = this.STask;
        await this.LoadAllDirections();
        this.LoadAllTasks();
        this.STask = _task;
        this.LoadUserReadyTasks();
      }
    },

    getSectionTasks() {
      this.ReadyTasksListRaw = [];
      this.ReadyUserTasks = [];
      this.TasksFiltered = [];
      this.TasksLoading = true;
      getSection(this.SDirection).then((response) => {
        let countL = 1;
        response.data.degrees.sort((a, b) => b.id - a.id);
        for (let i = 0; i < response.data.degrees.length; i++) {
          response.data.degrees[i].levels.sort((a, b) => a.id - b.id);
          for (let j = 0; j < response.data.degrees[i].levels.length; j++) {
            this.TasksFiltered.push(
              Object.assign(response.data.degrees[i].levels[j], {
                level: countL,
              })
            );
            countL++;
          }
        }
        this.TasksLoading = false;
      });
    },

    updateSolutions() {
      getAllSolutionNotChecked().then((response) => {
        this.solutions = response.data;
      });
    },

    handleSearchFilterSortingObject(Array) {
      this.tasks_filtered = Array;
    },
    handleFilterChanges(obj) {
      this.filters.selectedButton = obj.button;
      this.filters.selectedSearch = obj.search;
      this.filters.selectedSorting = obj.sorting;
    },

    handleEditorEditChanges(delta) {
      this.editSolutionForm.comment = this.$refs.QuillEditor.getHTML();
    },
    handleEditorEditReady() {
      this.$refs.QuillEditor.setHTML(this.editSolutionForm.comment);
    },

    sectionLevels() {
      let counter = 0;
      let section = this.options.filter((section) => section.id === this.value);
      section[0].degrees.forEach((degree) => {
        counter += degree.levels.length;
      });
      return counter;
    },

    sortingMethods(sort_name, array) {
      // {
      //       text: "Сортировка по умолчанию",
      //       val: "default",
      //     },
      // {
      //   text: "Сортировка по сдаче (Старое выше)",
      //   val: "task_waiting_high",
      // },
      // {
      //   text: "Сортировка по сдаче (Новое выше)",
      //   val: "task_waiting_low",
      // },
      //     {
      //       text: "Сортировка по студенту (А-Я)",
      //       val: "student_to_high",
      //     },
      //     {
      //       text: "Сортировка по студенту (Я-А)",
      //       val: "student_to_low",
      //     },
      //     {
      //       text: "Сортировка по номеру задания (Возрастание)",
      //       val: "task_to_high",
      //     },
      //     {
      //       text: "Сортировка по номеру задания (Убывание)",
      //       val: "task_to_low",
      //     },
      // user?.name surname
      // task?.level_id id

      if (sort_name === "student_to_high") {
        array = array.sort((a, b) => {
          let _a = `${a?.user?.surname}_${a?.user?.name}`;
          let _b = `${b?.user?.surname}_${b?.user?.name}`;
          return _a.toLowerCase().localeCompare(_b.toLowerCase());
        });

        return array;
      }

      if (sort_name === "student_to_low") {
        array = array.sort((a, b) => {
          let _a = `${a?.user?.surname}_${a?.user?.name}`;
          let _b = `${b?.user?.surname}_${b?.user?.name}`;
          return _b.toLowerCase().localeCompare(_a.toLowerCase());
        });

        return array;
      }

      if (sort_name === "task_to_high") {
        let __sortedLevels = this.sortedLevels.find((x) => x.id === this.value);
        __sortedLevels = __sortedLevels.levels;

        array = array.sort((a, b) => {
          let a_lvl = __sortedLevels.find((x) => x.id === a?.task?.level?.id);
          a_lvl = a_lvl.level;

          let b_lvl = __sortedLevels.find((x) => x.id === b?.task?.level?.id);
          b_lvl = b_lvl.level;

          return a_lvl - b_lvl;
        });

        return array;
      }

      if (sort_name === "task_to_low") {
        let __sortedLevels = this.sortedLevels.find((x) => x.id === this.value);
        __sortedLevels = __sortedLevels.levels;

        array = array.sort((a, b) => {
          let a_lvl = __sortedLevels.find((x) => x.id === a?.task?.level?.id);
          a_lvl = a_lvl.level;

          let b_lvl = __sortedLevels.find((x) => x.id === b?.task?.level?.id);
          b_lvl = b_lvl.level;

          return b_lvl - a_lvl;
        });

        return array;
      }

      if (sort_name === "task_waiting_high") {
        array = array.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );

        return array;
      }
      if (sort_name === "task_waiting_low") {
        array = array.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        return array;
      }

      return array;
    },
    // openEditExtern(solution) {
    //   this.editExternForm.sectionLevels = this.sectionLevels();
    //   findById(solution.user_id).then((user) => {
    //     let result = user.data.user_cells.filter(
    //       (cell) => cell.level.degree.section_id === this.value
    //     );
    //     this.editExternForm.studentLevels = result.length;
    //     this.editExternForm.newStudentLevels =
    //       this.editExternForm.studentLevels;
    //   });
    //   this.editExternForm.id = solution.id;
    //   this.editExternForm.direction_id = solution.direction_id;
    //   this.editExternForm.user_id = solution.user.id;
    //   this.editExternForm.task_id = null;
    //   this.editExternForm.extern_id = solution.id;
    //   this.dialogExternCheck = true;
    // },
    openEditSolutions(solution) {
      this.renderTaskHistory = false;
      this.editSolutionForm.id = solution.id;
      this.editSolutionForm.level_id = solution.task.level.id;
      this.editSolutionForm.task_id = solution.task_id;
      this.editSolutionForm.user_id = solution.user.id;
      this.editSolutionForm.extern_id = null;
      this.editSolutionForm.comment = "";
      this.renderTaskHistory = parseInt(
        parseInt(this.renderTaskHistory, 10) + 1,
        10
      );
      this.dialogCheck = true;
    },
    editSolutions(result) {
      this.editSolutionForm.status_id = result ? 1 : 5;
      updateSolution(this.editSolutionForm.id, this.editSolutionForm)
        .then(() => {
          getAllSolutionNotChecked().then((response) => {
            this.solutions = response.data;
          });
          this.dialogCheck = false;
          this.$message({
            type: "success",
            message: `Задача проверена!`,
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: `Произошла ошибка`,
          });
        });

      editSolutionForm.comment = "";
    },
    // editExternSolution(result) {
    //   this.editExternForm.status_id = result ? 1 : 3;
    //   updateExtern(this.editExternForm.id, this.editExternForm)
    //     .then(() => {
    //       getAllNotCheckedExtern().then((response) => {
    //         this.solutions = response.data;
    //       });
    //       this.dialogCheck = false;
    //       this.$message({
    //         type: "success",
    //         message: `Заявка проверена!`,
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "error",
    //         message: `Произошла ошибка`,
    //       });
    //     });
    // },

    async LoadAllDirections() {
      let res = await getAllSolutions();
      this.ReadyTasksListRaw = res.data;

      let Directions = [];
      for (let i of this.ReadyTasksListRaw) {
        let direction = i?.task?.level?.degree?.section;
        if (direction != null) {
          let result = Directions.find((obj) => obj.id === direction.id);

          if (result == null) {
            Directions.push(direction);
          }
        }
      }

      this.Directions = Directions;
      this.TasksLoading = false;
    },

    LoadAllTasks() {
      this.TasksLoading = true;
      this.STask = "";
      this.ReadyUserTasks = [];

      let Tasks = [];
      for (let i of this.ReadyTasksListRaw) {
        let task = i?.task?.level;
        if (task != null) {
          if (task.degree.section_id == this.SDirection) {
            let result = Tasks.find((obj) => obj.name === task.name);
            if (result == null) {
              Tasks.push(task);
            }
          }
        }
      }

      this.TasksFiltered = Tasks;
      this.TasksLoading = false;
    },

    async LoadUserReadyTasks() {
      this.readyTasks.offset = 0;
      this.readyTasks.loadMoreEnable = true;
      this.ReadyUserTasks = [];
      await this.LoadMoreTask();
    },
    async LoadMoreTask() {
      this.TasksLoading = true;
      try {
        let solutionsResponse = await getAllSolutionsByTask(this.STask, {
          offset: this.readyTasks.offset,
          count: this.readyTasks.counter,
        });

        this.readyTasks.offset =
          this.readyTasks.offset + this.readyTasks.counter;

        this.ReadyTasksListRaw = solutionsResponse.data;
        if (this.ReadyTasksListRaw.length < this.readyTasks.counter) {
          this.readyTasks.loadMoreEnable = false;
        }

        this.ReadyUserTasks = this.ReadyUserTasks.concat(
          solutionsResponse.data
        );
      } catch (e) {
        alert("При загрузке возникла ошибка, подробности в консоли");
        console.log("Ошибка при загрузке данных:", e);
      }
      this.TasksLoading = false;
    },

    ReplaceNullToEmptyString(str) {
      if (str == null) {
        return "";
      }

      return str;
    },
    GetFilename(str) {
      if (str == null) {
        return "null";
      }

      let a = str.indexOf("-");
      return str.substr(a + 1);
    },
    GetCorrectDate(str) {
      if (str == null) {
        return "null";
      }

      let a = str.split("T");
      a = a[0].split("-");
      a = a.reverse();
      return `${a[0]}.${a[1]}.${a[2]}`;
    },
  },
};
</script>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.HideSearchPanel:deep(.SearchInput) {
  display: none;
}
.HideSearchPanel {
  margin-bottom: 50px;
}

.name-user {
  text-align: left;
  width: 100%;
}

.SelectBlock {
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.SelectBlock > .Title {
  text-align: left;
  margin-left: 8px;
}

.SubTask {
  font-size: 20px;

  margin-bottom: 16px;
}
.FIO {
  font-size: 18px;

  margin-bottom: 36px;
}
.Status {
  font-size: 16px;

  margin-bottom: 12px;
}
.Date {
  font-size: 16px;

  margin-bottom: 12px;
}

.UserComment,
.AdminAnswer {
  position: relative;

  display: flex;
  flex-direction: column;

  margin-bottom: 12px;
}

.UserComment > :not(.UCBg),
.AdminAnswer > :not(.UCBg) {
  position: relative;

  width: 100%;
  height: fit-content;

  font-size: 16px;

  margin-bottom: 4px;
}

.UserComment span,
.AdminAnswer span {
  display: block;
}

.UserComment > .UCBg {
  position: relative;

  width: 100%;
  height: fit-content;

  font-size: 16px;

  padding: 10px;

  border-radius: 6px;

  margin-bottom: 12px;

  background-color: #1e1c46;
}

.AdminAnswer > .UCBg {
  position: relative;

  width: 100%;
  height: fit-content;

  font-size: 16px;

  padding: 10px;

  border-radius: 6px;

  margin-bottom: 12px;

  background-color: #293148;
}

.TasksList {
  position: relative;

  padding: 40px 0%;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;

  row-gap: 30px;
}
.TasksList > .Card {
  position: relative;

  padding: 18px 24px;

  width: auto;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-color: rgba(70, 68, 103, 0.7);
  border-radius: 10px;
}

.TasksList > .Card > span,
.TasksList > .Card > a {
  display: block;

  line-height: 120%;

  word-wrap: break-word;
  word-break: break-word;

  text-align: left;
}

.TasksList > .Card > a {
  font-size: 16px;

  color: #938eff;

  padding: 10px;
  border: 1px solid #938eff;
  border-radius: 6px;

  width: fit-content;
}
.TasksList > .Card > a:hover {
  color: #aeaaff !important;
  border: 1px solid #aeaaff !important;
}

.ReadyTasks {
  position: relative;

  width: fit-content;
  height: fit-content;

  margin: 40px 0%;
}

.task-card > .card-username {
  position: relative;
  width: 100%;

  text-align: left;

  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid white;

  padding-bottom: 12px;
}
.task-card > .card-username .static-text {
  font-size: 1.25rem;
  font-weight: 400;
}
.task-card > .card-username .static-text-username {
  font-size: 1.15rem;
  font-weight: 600;

  margin-left: 10px;
}
.externRate {
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
}
.externDesc {
  display: flex;
  flex-direction: row;
}
.extern-type {
  margin-bottom: 40px !important;
}
.header-type-sol {
  font-size: 20px !important;
  font-weight: 600;
  display: flex;
}
.download-btn {
  border: none;
  background-color: none;
  color: #d64a4a;
  margin: 0px 10px;
}
.check-btn {
  margin: 20px 0px;
  border: none;
  background-color: #d64a4a;
  color: white;
  padding: 5px 20px;
  border-radius: 15px;
}
hr {
  color: #464467;
  width: 100%;
  height: 2px;
}
.task-card {
  background-color: #1e1c46;
  padding: 30px 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(77, 77, 77, 0.5);

  margin-bottom: 60px;
}
.el-select-dropdown__item {
  color: white;
}
.el-select-dropdown__item:hover {
  background: #5f5e5eb2;
}
.el-select-dropdown__item.selected {
  background: #5f5e5e49;
}
.tags-p {
  margin-right: 20px;
}
.direction {
  margin: 30px 0px;
  align-items: center;
  display: flex;
  width: 70%;
}
h2 {
  display: flex;
  font-weight: 600;
}

.container {
  color: white;
  font-family: "Montserrat";
  padding: 100px 100px;
}
.checking {
  min-height: 100vh;
  background-position: 100% 135% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: 30% !important;
  z-index: 1000;
}
</style>
