<template>
  <div class="Tests">
    <div class="ProjectList noselect">
      <span class="Title">
        Список проектов
      </span>

      <div
        v-for="proj in userProjectsList"
        :key="proj"
        @click="
          selectedProjectForEdit == null
            ? (selectedProjectForEdit = proj.id)
            : selectedProjectForEdit == proj.id
            ? (selectedProjectForEdit = null)
            : (selectedProjectForEdit = proj.id);

          selectedJobForEdit = null;
        "
        :class="{
          active: selectedProjectForEdit == proj.id,
        }"
      >
        <span>{{ `${proj.name} (${proj.id})` }}</span>
        <span
          :style="
            `color: ${
              proj.archieve ? 'rgb(235, 87, 87)' : 'rgb(111, 207, 151)'
            };`
          "
          >{{ `Архив: ${proj.archieve ? "Да" : "Нет"}` }}</span
        >
        <div
          class="ql-snow"
          style="position: relative; display: block; width: 100%; height: fit-content; border: 1px solid white;"
        >
          <span
            style="position: relative; display: block; width: 100%; height: fit-content; text-align: left;"
            >Описание:
          </span>
          <span
            class="ql-editor"
            style="position: relative; display: block; width: 100%; height: fit-content;"
            v-html="proj.description"
          >
          </span>
        </div>
      </div>
    </div>

    <div class="JobsList">
      <div class="Head">
        <span class="Title">
          Список задач у проекта
        </span>

        <div class="Actions" v-if="selectedProjectForEdit != null">
          <GradientButton
            ButtonText="Изменить"
            @click.native="
              loadEditData(selectedJobForEdit);
              modalMode = 'edit';
              jobEditOpened = true;
            "
            v-if="selectedJobForEdit != null"
          />
          <GradientButton
            ButtonText="Добавить"
            @click.native="
              modalMode = 'create';
              jobEditOpened = true;
            "
            v-if="selectedJobForEdit == null"
          />
          <GradientButton
            ButtonText="Удалить"
            @click.native="removeJob"
            v-if="selectedJobForEdit != null"
          />
        </div>

        <!-- <span @click="createEmptyJob" v-if="selectedJobForEdit == null"
            >+</span
          >
          <span @click="removeJob" v-if="selectedJobForEdit != null">-</span> -->
      </div>
      <template v-if="selectedProjectForEdit != null">
        <div class="Body">
          <div
            class="JobContainer ql-snow"
            v-for="job in jobsList"
            v-show="job.projectId == selectedProjectForEdit"
            :key="job"
            @click="
              selectedJobForEdit == null
                ? (selectedJobForEdit = job.id)
                : selectedJobForEdit == job.id
                ? (selectedJobForEdit = null)
                : (selectedJobForEdit = job.id)
            "
            :class="{
              active: selectedJobForEdit == job.id,
              job: true,
              noselect: true,
            }"
          >
            <span class="title"> {{ job.name }} </span>
            <span
              :style="
                `color: ${
                  job.archieve ? 'rgb(235, 87, 87)' : 'rgb(111, 207, 151)'
                };`
              "
              >{{ `Архив: ${job.archieve ? "Да" : "Нет"}` }}</span
            >
            <span
              :style="
                `color: ${
                  job.jobHire ? 'rgb(182, 87, 235)' : 'rgb(111, 207, 151)'
                };`
              "
              >{{ `Трудоустройство: ${job.jobHire ? "Да" : "Нет"}` }}</span
            >
            <span
              :style="
                `color: ${
                  job.price != null ? 'rgb(182, 87, 235)' : 'rgb(111, 207, 151)'
                };`
              "
              >{{ `Задача платная: ${job.price != null ? "Да" : "Нет"}` }}</span
            >
            <span>Уровень сложности: {{ job.level }}</span>
            <span>Направление: {{ directionsList(job.sections) }}</span>
            <div
              class="ql-snow"
              style="position: relative; display: block; width: 100%; height: fit-content; border: 1px solid white;"
            >
              <span
                style="position: relative; display: block; width: 100%; height: fit-content; text-align: left;"
                >Описание:
              </span>
              <span
                class="ql-editor"
                style="position: relative; display: block; width: 100%; height: fit-content;"
                v-html="job.description"
              >
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>

    <el-dialog
      v-model="jobEditOpened"
      :title="modalMode == 'edit' ? 'Редактирование задачи' : 'Создание задачи'"
      width="40%"
    >
      <div class="EditModal">
        <div
          style="position: relative; display: flex; flex-direction: column; gap: 10px; width: fit-content;"
        >
          <div class="checkbox_agreement">
            <input
              type="checkbox"
              id="advert_checkbox"
              name="advert_checkbox"
              v-model="jobarchieve"
              style="width: 24.4px !important;"
            />
            <label for="advert_checkbox">Архив</label>
          </div>

          <template v-if="modalMode != 'project'">
            <div class="checkbox_agreement">
              <input
                type="checkbox"
                id="advert_checkbox"
                name="advert_checkbox"
                v-model="jobhire"
                style="width: 24.4px !important;"
              />
              <label for="advert_checkbox">Есть трудоустройство</label>
            </div>

            <div
              style="position: relative; display: flex; flex-direction: column; gap: 5px; width: 250px;"
            >
              <div class="checkbox_agreement">
                <input
                  type="checkbox"
                  id="advert_checkbox"
                  name="advert_checkbox"
                  v-model="jobpriceBoolean"
                  style="width: 24.4px !important;"
                />
                <label for="advert_checkbox">Задача платная</label>
              </div>

              <input
                type="text"
                placeholder="если == 0, задача не платная"
                v-model="jobpriceNumber"
                v-if="jobpriceBoolean"
              />
            </div>
          </template>
        </div>

        <input type="text" v-model="jobname" placeholder="Название задачи" />
        <h3 style="color: white !important; ">
          Описание задачи
        </h3>
        <div
          class="QEditor QEditorFixHeight"
          style="position: relative; width: auto; height: 400px;"
        >
          <QuillEditor
            ref="QuillEditorAJD"
            :modules="modules"
            toolbar="full"
            theme="snow"
            contentType="html"
            :content="jobdesc"
            @textChange="
              handleEditorEditChanges($event, 'AJD', 'QuillEditorAJD')
            "
            @ready="handleEditorEditReady('AJD', 'QuillEditorAJD')"
          />
        </div>

        <h3 style="color: white !important; ">
          Укажите сложность задачи
        </h3>
        <el-select
          v-model="DifficultySelected"
          class="m-2"
          placeholder="Select"
          size="large"
        >
          <el-option
            v-for="item in DifficultyLevels"
            :key="item.difficult"
            :label="item.name"
            :value="item.name"
          />
        </el-select>

        <h3 style="color: white !important; ">
          Требуемые компетенции
        </h3>

        <GradientButton
          v-if="SectionsList.length > SectionsSelected.length"
          ButtonText="Добавить"
          @click.native="addSectionForJob"
        />

        <div class="CompetenceContainer">
          <div
            v-for="(sect, sindx) in SectionsSelected"
            :key="`AddedSection-${sindx}-${sect.id}`"
            class="SectionAdded"
          >
            <template v-if="sect.id === null">
              <h4 style="text-align: left;">Выберите направление</h4>
              <el-select
                v-model="sect.id"
                class="m-2"
                placeholder="Select"
                size="large"
                style="margin: 0% !important; display: block !important;"
              >
                <el-option
                  v-for="item in availableCompetences"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </template>

            <template v-else>
              <div class="CompBlock" style="min-height: 79px;">
                <Competence
                  class="CompRestyle"
                  :header="false"
                  :userLevels="sect.selectedLevels"
                  :useWhiteListRequireBlock="true"
                  :whiteListRequireBlock="[[]]"
                  :competenceWhiteList="[sect.id]"
                  :viewMode="true"
                  :clickHandler="levelsSelectHandler"
                  :key="`ComptenceBlock-${sect.id}-${levelsHash}`"
                />
                <GradientButton
                  ButtonText="Удалить"
                  @click.native="removeSectionForJob(sindx)"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              () => {
                jobEditOpened = false;
                SectionsSelected = [];
              }
            "
            >Отмена</el-button
          >
          <template v-if="modalMode == 'edit'">
            <el-button
              type="primary"
              @click="
                UpdateJob();
                jobEditOpened = false;
              "
            >
              Сохранить
            </el-button>
          </template>
          <template v-else>
            <el-button
              type="primary"
              @click="
                createEmptyJob();
                jobEditOpened = false;
              "
            >
              Создать
            </el-button>
          </template>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getSections } from "@/api/competence.js";

import { findById } from "@/api/user.js";
import { mapGetters, mapMutations } from "vuex";
import { refresh } from "@/api/auth.js";

import Competence from "@/components/Profile/CompetenceBlock.vue";

import { findAllAdmin } from "@/api/project.js";
import {
  getAll,
  createJob,
  findAllJobByUser,
  removeJob,
  getAllDifficulties,
  updateJob,
} from "@/api/job.js";

import { h } from "vue";
import { ElNotification } from "element-plus";

import GradientButton from "@/components/CustomElements/ButtonGradient.vue";

import { QuillEditor } from "@vueup/vue-quill";

import ImageCompress from "quill-image-compress";
import { VEUploadImage } from "@/api/visual_editor.js";

export default {
  components: { GradientButton, QuillEditor, Competence },
  setup: () => {
    const moduleImageCompress = {
      name: "imageCompress",
      module: ImageCompress,
      options: {
        quality: 0.8,
        maxWidth: 10000, // default
        maxHeight: 10000, // default
        imageType: "image/webp",
        keepImageTypes: ["image/jpeg", "image/png", "image/webp"],
        debug: false,
        insertIntoEditor: (imageBase64URL, imageBlob, editor) => {
          const formData = new FormData();
          formData.append("file", imageBlob);

          VEUploadImage(formData).then((x) => {
            if (x.data == null) {
              alert("Ошибка при загрузке файла");
            } else {
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", `${x.data}`, "user");
            }
          });
        },
      },
    };

    const modules = [moduleImageCompress];

    return { modules };
  },
  data() {
    return {
      jobsList: [],
      userProjectsList: [],

      selectedProjectForEdit: null,
      selectedJobForEdit: null,
      jobEditOpened: false,

      jobname: "",
      jobdesc: "",
      jobarchieve: "",
      jobhire: "",
      jobpriceBoolean: false,
      jobpriceNumber: null,

      modalMode: "",

      DifficultySelected: "Средний",
      DifficultyLevels: [],

      SectionSelected: "",
      SectionsList: [],

      SectionsSelected: [],
      LevelsSelected: [],
    };
  },
  async created() {
    if (this.user == null) {
      this.updateUser();
    } else {
      this.getUser(this.user.id);
    }

    let projectListRaw = await findAllAdmin();
    this.userProjectsList = projectListRaw.data;

    this.getActualJobList();

    //заполнение блока компетенций направлениями, уровнями пользователя и необходимыми уровнями для проекта
    getSections().then((response) => {
      let sections = response.data;
      let competence = [];

      let self = this;

      let tags = new Set(sections.map((section) => section.tag));
      tags.forEach((tag) => {
        let filteredSections = sections
          .filter((section) => section.tag === tag)
          .map((section) => {
            return {
              name: section.name,
              id: section.id,
              levels: section.degrees
                .flatMap((degree) => degree.levels.reverse())
                .reverse()
                .map((x) => x.id),
            };
          });
        competence.push({
          name: tag,
          counter: filteredSections.length,
          CBlock: filteredSections,
        });
      });

      if (competence.length >= 1) {
        self.SectionsList = competence[0].CBlock;

        if (competence[0].CBlock.length > 0) {
          self.SectionSelected = competence[0].CBlock[0].id;
        }
      }
    });

    getAllDifficulties().then((response) => {
      this.DifficultyLevels = response.data;
      this.DifficultySelected = "Средний";
    });
  },
  watch: {
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
    availableCompetences() {
      if (this.SectionsList == null || this.SectionsList.length === 0) {
        return [];
      }

      return this.SectionsList.filter((x) => {
        let findInSelected = this.SectionsSelected.find((i) => x.id === i.id);

        if (findInSelected == null) {
          return true;
        }

        return false;
      });
    },

    levelsHash() {
      return this.SectionsSelected.map((x) => {
        return `${x.id}-${this.LevelsSelected.join("_")}`;
      }).join("/");
    },

    jobLevel() {},
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),
    async updateUser() {
      const response = await refresh();
      const userResponse = response.user;
      this.getUser(userResponse.id);
    },
    getUser(id) {
      if (id != undefined) {
        findById(id).then((response) => {
          this.SET_USER(response.data);
        });
      }
    },

    async loadEditData(job_id) {
      let JobForEdit = this.jobsList.find((obj) => {
        return obj.id === job_id;
      });

      if (job_id == null) {
        alert("Возникла какая-то ошибка!");
        return 0;
      }

      let objCopy = JSON.parse(JSON.stringify(JobForEdit));

      this.jobname = objCopy.name;
      this.jobdesc = objCopy.description;
      this.jobarchieve = objCopy.archieve;
      this.jobhire = objCopy.jobHire;
      this.jobpriceBoolean = objCopy.price != 0 && objCopy.price != null;
      this.jobpriceNumber = objCopy.price;
      this.SectionSelected = objCopy.sectionId;

      this.DifficultySelected = objCopy.level;
      this.SectionsSelected = objCopy.sections.map((x) => {
        let findSect = this.SectionsList.find((i) => i.id === x.id);
        if (findSect == null) {
          return { ...x, selectedLevels: [] };
        }

        let sel = objCopy.levels
          .filter((lvl) => {
            return findSect.levels.includes(lvl.id);
          })
          .map((_lvl) => _lvl.id);
        return { ...x, selectedLevels: sel };
      });
      this.LevelsSelected = objCopy.levels.map((x) => x.id);

      this.handleEditorEditReady("AJD", "QuillEditorAJD");
    },

    async createEmptyJob() {
      let startJobData = {
        name: this.jobname,
        description: this.jobdesc,
        projectId: this.selectedProjectForEdit,
        level: this.DifficultySelected,
        // authorId: this.user.id,
        authorId: null,
        order: this.jobsList.length + 1,

        sections: this.SectionsSelected.map((x) => x.id),
        levels: this.SectionsSelected.map((x) => x.selectedLevels).flat(),

        archieve: false,
        jobHire: false,
        price: null,
      };

      await createJob(startJobData);
      this.getActualJobList();
    },

    async UpdateJob() {
      let self = this;

      let startJobData = {
        name: this.jobname,
        description: this.jobdesc,
        projectId: this.selectedProjectForEdit,
        level: this.DifficultySelected,
        // authorId: this.user.id,
        authorId: null,
        order: this.jobsList.length + 1,

        sections: this.SectionsSelected.map((x) => x.id),
        levels: this.SectionsSelected.map((x) => x.selectedLevels).flat(),

        archieve: this.jobarchieve,
        jobHire: this.jobhire,
        price: this.jobpriceBoolean
          ? this.jobpriceNumber == 0
            ? null
            : this.jobpriceNumber
          : null,
      };

      await updateJob(this.selectedJobForEdit, startJobData);
      this.getActualJobList();
    },

    async removeJob() {
      let JobsArray = [];
      JobsArray.push(this.selectedJobForEdit);

      await removeJob(JobsArray);
      this.selectedJobForEdit = null;

      this.getActualJobList();
    },

    async getActualJobList() {
      let temp = await getAll();
      this.jobsList = temp.data;

      console.log(temp.data);
    },

    getDirectionByID(sec_id) {
      let Section = this.SectionsList.find((sec) => {
        return String(sec_id) === String(sec.id);
      });

      if (Section != null) {
        return Section.name;
      } else {
        return "";
      }
    },

    handleEditorEditChanges(delta, setTo, fromEditorName) {
      let htmlDataFromEditor = this.$refs[fromEditorName].getHTML();
      switch (setTo) {
        case "AJD": {
          this.jobdesc = htmlDataFromEditor;
          break;
        }
      }
    },
    handleEditorEditReady(setTo, fromEditorName) {
      let dataToSet = "";
      switch (setTo) {
        case "AJD": {
          dataToSet = this.jobdesc;
          break;
        }
      }
      this.$refs[fromEditorName].setHTML(dataToSet);
    },

    addSectionForJob() {
      let temp = {
        id: null,
        selectedLevels: [],
      };
      this.SectionsSelected.push(temp);
    },
    removeSectionForJob(indx) {
      this.SectionsSelected.splice(indx, 1);
    },
    levelsSelectHandler(sectID, lvlID) {
      let findSectInSelected = this.SectionsSelected.findIndex(
        (x) => x.id === sectID
      );

      if (findSectInSelected === -1) {
        return 0;
      }

      let copySect = JSON.parse(
        JSON.stringify(this.SectionsSelected[findSectInSelected])
      );

      if (copySect?.selectedLevels == null) {
        copySect["selectedLevels"] = [];
      }

      let _finded = copySect.selectedLevels.findIndex((x) => x === lvlID);

      if (_finded === -1) {
        copySect.selectedLevels.push(lvlID);
      } else {
        copySect.selectedLevels.splice(_finded, 1);
      }

      this.SectionsSelected.splice(findSectInSelected, 1, copySect);
      //

      let __finded = this.LevelsSelected.findIndex((x) => x === lvlID);

      if (__finded === -1) {
        this.LevelsSelected.push(lvlID);
      } else {
        this.LevelsSelected.splice(__finded, 1);
      }
    },

    directionsList(sects) {
      return sects.map((x) => x.name).join(", ");
    },
  },
};
</script>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.EditModal {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}

.Actions {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.JobContainer {
  position: relative;

  cursor: pointer;

  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  background-color: black;
  border-radius: 15px;
}
.JobContainer > .title {
  font-size: 22px;
}
.JobContainer > * {
  text-align: start;
}

.Tests {
  position: relative;

  padding-top: 60px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;

  color: white;
}

.ProjectList {
  position: relative;

  width: 50%;

  padding: 12px;
  margin: 0% auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  height: 90vh;
  overflow-y: auto;

  flex-shrink: 1;
}

.ProjectList > div {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 24px;

  border-radius: 15px;

  cursor: pointer;

  background-color: black;
}
.ProjectList > div > span {
  text-align: left;
}
.ProjectList > div > span:nth-child(1) {
  font-size: 22px;
}

.ProjectList > div.active {
  position: relative;

  color: white;

  background-color: rgb(48, 90, 174);

  filter: drop-shadow(00px 00px 6px rgb(48, 90, 174));
}
.active {
  position: relative;

  color: white;

  background-color: rgb(48, 90, 174);

  filter: drop-shadow(00px 00px 6px rgb(48, 90, 174));
}

.JobsList {
  width: 50%;
  height: 90vh;
  overflow-y: auto;

  flex-shrink: 1;
}

.JobsList,
.JobsList > .Body {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: left;

  padding: 12px;

  height: 90vh;
  overflow-y: auto;

  gap: 20px;
}
.JobsList > .Head {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 40px;
}

.Title {
  position: relative;

  font-size: 24px;
  font-weight: 600;

  flex-shrink: 0;
}
</style>

<style scoped>
.checkbox_agreement {
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.3rem;
  align-items: center;
}

.checkbox_agreement a {
  font-weight: 500;
  color: #6c59f7;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #6e6a9a;
  border-radius: 4px;
  padding: 0.7rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-right: 1rem;
}

input[type="checkbox"]:checked {
  background-image: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>

<style scoped>
.CompRestyle:deep(.comp-lvls) {
  margin: 0%;
}
.CompRestyle:deep(.comp-lvl) {
  padding: 0%;
  gap: 6px;
}

.CompetenceContainer {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}
.CompBlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
</style>
