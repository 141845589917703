<template>
  <div class="admin-organization-projects ulr-center-block">
    <div class="admin-content">
      <!-- Заголовок страницы -->
      <el-container class="ulr-center-block">
        <h2>Список проектов на модерацию от госструктур</h2>
      </el-container>

      <!-- Фильтры -->
      <el-container class="ulr-center-block project-filters">
        <div>
          <h6>Госучреждение</h6>
          <el-select
            v-model="filter_organizations"
            multiple
            filterable
            placeholder=""
            @change="filterProjects"
          >
            <el-option
              v-for="item in projects_organizations"
              :key="item.id"
              :label="item.organization_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <h6>Статус проекта</h6>
          <el-select
            v-model="filter_statuses"
            filterable
            placeholder=""
            @change="filterProjects"
          >
            <el-option
              v-for="item in projects_statuses"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <el-button
            type="primary"
            @click="
              clearProjectEditForm();
              project_mode_add = true;
              dialog_edit_show = true;
            "
            >Добавить проект</el-button
          >
        </div>
      </el-container>

      <!-- Проекты -->
      <el-container class="ulr-center-block">
        <el-row>
          <template v-if="filter_projects.length != 0">
            <el-col v-for="(o, index) in filter_projects" :key="index">
              <OrganizationProjectCard
                :o="o"
                :runEdit="projectHandleEdit"
                :runDelete="projectHandleDelete"
                :adminEditMode="true"
              />
            </el-col>
          </template>
          <template v-else>
            <h5>Пусто</h5>
          </template>
        </el-row>
      </el-container>
    </div>

    <div class="bg-picture"></div>
  </div>

  <el-dialog v-model="dialog_delete_show" title="Удалить проект" width="25%">
    <span style="font-size: 1vw;">Вы уверены что хотите удалить проект?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialog_delete_show = false">Отменить</el-button>
        <el-button type="danger" @click="projectAdminDelete">Удалить</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="dialog_edit_show"
    :title="
      project_mode_add == false ? `Модерация проекта` : `Добавление проекта`
    "
    width="40%"
  >
    <div class="project-moderation">
      <!-- Фотография проекта -->
      <div>
        <div class="details-header">
          <h5>Фотография проекта</h5>
        </div>
        <div class="details-body ulr-center-block flex-column">
          <template v-if="project_mode_add == false">
            <div
              class="project-img-block"
              :style="
                project_edit_copy.img == null
                  ? `background-size: 150px auto !important; background-image: url(/img/user_icon.f5012049.png);`
                  : `background-image: url(${project_edit_copy.img.url.replaceAll(
                      '\\',
                      '/'
                    )});`
              "
            ></div>
          </template>

          <template v-else>
            <el-upload
              class="avatar-uploader"
              action="#"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleAvatarUpload"
            >
              <div
                v-if="project_edit_copy.img != null"
                :src="project_edit_copy.img.url"
                class="project-img-block"
                :style="
                  project_edit_copy.img == null
                    ? `background-size: 150px auto !important; background-image: url(/img/user_icon.f5012049.png);`
                    : `background-image: url(${project_edit_copy.img.url.replaceAll(
                        '\\',
                        '/'
                      )});`
                "
              ></div>
              <div
                v-else
                class="project-img-block"
                :style="
                  project_edit_copy.img == null
                    ? `background-size: 150px auto !important; background-image: url(/img/user_icon.f5012049.png);`
                    : `background-image: url(${project_edit_copy.img.url.replaceAll(
                        '\\',
                        '/'
                      )});`
                "
              ></div>
              <div class="el-upload__text">
                Фото проекта (желательно 400x300)
              </div>
            </el-upload>
          </template>
          <div>
            <template v-if="project_mode_add == true">
              <el-button
                v-if="project_edit_copy.img != null"
                type="danger"
                @click="handleAvatarDelete()"
                >Удалить</el-button
              >
            </template>
          </div>
        </div>
      </div>

      <!-- Детали проекта -->
      <div>
        <div class="details-header">
          <h5>Детали проекта</h5>
        </div>
        <div class="details-body ulr-center-block flex-column">
          <div>
            <p>Название проекта</p>
            <el-input
              v-model="project_edit_copy.name"
              placeholder="Название проекта"
            />
          </div>
          <div>
            <p>Краткое описание</p>
            <el-input
              type="textarea"
              v-model="project_edit_copy.desc_short"
              placeholder="Краткое описание"
            />
          </div>
          <div>
            <p>Полное описание</p>
            <el-input
              type="textarea"
              v-model="project_edit_copy.desc_full"
              placeholder="Полное описание"
            />
          </div>
          <div>
            <p>Контакты</p>
            <el-input
              type="textarea"
              v-model="project_edit_copy.contacts"
              placeholder="Контакты"
            />
          </div>
        </div>
      </div>

      <!-- Направление проекта -->
      <div>
        <div class="details-header">
          <h5>Направления проекта</h5>
        </div>
        <div class="details-body ulr-center-block flex-column">
          <div>
            <p>Направления что одобрены в БД</p>
            <el-select
              v-model="project_edit_copy.tags_accepted"
              multiple
              filterable
              placeholder="Выберите направления для фильтрации"
            >
              <el-option
                v-for="item in all_directions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="project_mode_add == false">
            <p>Предложенные направления, что ожидают модерации</p>
            <div class="custom_direction_list">
              <template
                v-if="project_edit_copy.tags_awaiting_moderation.length != 0"
              >
                <div
                  v-for="item in project_edit_copy.tags_awaiting_moderation"
                  class="ulr-center-block flex-column"
                >
                  <span
                    class="badge badge-default"
                    :style="`background-color: ${item.color} !important;`"
                    :key="item.id"
                    >{{ item.name }}</span
                  >
                  <div class="ulr-center-block">
                    <el-button
                      type="success"
                      @click="changeCustomDirectionStatus(item.id, true)"
                      >Добавить в БД</el-button
                    >
                    <el-button
                      type="danger"
                      @click="changeCustomDirectionStatus(item.id, false)"
                      >Удалить (в том числе из БД)</el-button
                    >
                  </div>
                </div>
              </template>
              <template v-else>
                <p>Пусто!</p>
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- Доп. файлы проекта -->
      <div>
        <div class="details-header">
          <h5>Доп. файлы проекта</h5>
        </div>
        <div class="details-body ulr-center-block flex-column">
          <template v-if="project_mode_add == true">
            <el-upload
              class="upload-demo"
              :limit="1"
              :auto-upload="false"
              :on-change="handleFileUpload"
              :on-remove="handleRemove"
              :file-list="project_add_files_list"
            >
              <p>Доп. материалы</p>
              <el-button type="primary">Загрузить</el-button>
              <template #tip>
                <div class="el-upload__tip">Только 1 файл, формата .zip</div>
              </template>
            </el-upload>
          </template>
          <template v-else>
            <div v-if="project_add_files_list.length != 0">
              <p>{{ project_add_files_list[0]["name"] }}</p>
              <a :href="project_add_files_list[0][`url`]" target="_blank">
                <el-button type="info">Скачать</el-button>
              </a>
            </div>
            <div v-else>
              <p>Пусто!</p>
            </div>
          </template>
        </div>
      </div>

      <!-- Бинд к госструктурам -->
      <div v-if="project_mode_add == true">
        <h6>Госучреждение</h6>
        <el-select
          v-model="project_bind_organization"
          filterable
          placeholder=""
        >
          <el-option
            v-for="item in projects_organizations"
            :key="item.id"
            :label="item.organization_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <!-- Вердикт -->
      <div v-if="project_mode_add == false">
        <div class="details-header">
          <h5>Вердикт</h5>
        </div>
        <div class="details-body ulr-center-block flex-column">
          <div>
            <p>Статус проекта</p>
            <el-select
              v-model="project_edit_copy.status"
              filterable
              placeholder=""
              @change="filterProjects"
            >
              <el-option
                v-for="item in projects_statuses"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <div v-if="project_edit_copy.status == 7">
            <p>Комментарий</p>
            <el-input
              type="textarea"
              v-model="project_edit_copy.comment"
              placeholder="Комментарий"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer ulr-center-block">
        <template v-if="project_mode_add == false">
          <el-button type="info" @click="dialog_edit_show = false"
            >Отменить проверку</el-button
          >
          <el-button type="danger" @click="dialog_delete_show = true"
            >Удалить проект</el-button
          >
          <el-button type="success" @click="checkValidation()"
            >Сохранить изменения</el-button
          >
        </template>
        <template v-else>
          <el-button
            type="info"
            @click="
              dialog_edit_show = false;
              project_mode_add = false;
            "
            >Отменить создание</el-button
          >
          <el-button type="success" @click="checkValidation()"
            >Создать проект</el-button
          >
        </template>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import OrganizationProjectCard from "@/components/Organization/OrgProjectCard.vue";

import { refresh } from "@/api/auth.js";
import { findById } from "@/api/user.js";
import { mapMutations } from "vuex";

import { h } from "vue";
import { ElNotification } from "element-plus";

import {
  getAllDirection,
  updateDirection,
  deleteDirection,
} from "@/api/direction.js";
import {
  getAllProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
} from "@/api/organization_projects.js";
import { getAllOrganizations } from "@/api/user_organization.js";

export default {
  components: { OrganizationProjectCard },
  data() {
    return {
      filter_projects: [],
      all_projects: [],

      filter_statuses: [],
      projects_statuses: [
        {
          id: 6,
          name: "Ожидает модерации",
        },
        {
          id: 7,
          name: "Отклонен",
        },
        {
          id: 8,
          name: "Опубликован",
        },
      ],

      filter_organizations: [],
      projects_organizations: [],

      project_bind_organization: null,
      project_mode_add: false,
      project_selected_id: null,
      project_add_files_list: [],
      project_edit_copy: {
        img: null,
        file: null,
        name: "",
        desc_short: "",
        desc_full: "",
        contacts: "",
        comment: "",
        tags_accepted: [],
        tags_awaiting_moderation: [],
        status: null,
      },

      all_directions: [],
      file_list: [],

      dialog_delete_show: false,
      dialog_edit_show: false,
    };
  },
  async created() {
    this.updateUser();
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),

    /* Получение списка проектов и организаций & фильтрация */
    async getProjectsList() {
      let projects = await getAllProjects();
      this.all_projects = projects.data;
      this.filterProjects();
    },
    async getOrganizationsList() {
      let organizations = await getAllOrganizations();
      this.projects_organizations = organizations.data;
      this.filterProjects();
    },
    async getApprovedDirectionsList() {
      let all_directions = await getAllDirection();
      this.all_directions = all_directions.data;
    },
    filterProjects() {
      this.filter_projects = [];

      if (
        this.filter_statuses.length == 0 &&
        this.filter_organizations.length == 0
      ) {
        this.filter_projects = this.all_projects;
        return;
      }

      for (let obj in this.all_projects) {
        if (
          this.filter_statuses.length != 0 &&
          String(this.all_projects[obj]["project_status"]) !=
            String(this.filter_statuses)
        ) {
          continue;
        }

        if (
          this.filter_organizations.length != 0 &&
          !this.filter_organizations.includes(
            parseInt(this.all_projects[obj]["organizationId"])
          )
        ) {
          continue;
        }

        this.filter_projects.push(this.all_projects[obj]);
      }
    },
    /* END Получение списка проектов и организаций */

    /* Обновление списка направлений */
    async updateDirectionsList() {
      await this.getApprovedDirectionsList();

      let res = await getProject({ id: this.project_selected_id }, {});
      if (res.data["Directions"].length != 0 && res.data["Directions"] != "") {
        this.project_edit_copy.tags_accepted = [];
        this.project_edit_copy.tags_awaiting_moderation = [];

        if (res.data.hasOwnProperty("Directions")) {
          for (let obj in res.data["Directions"]) {
            if (
              this.all_directions.find(
                (el) =>
                  parseInt(el.id) == parseInt(res.data["Directions"][obj]["id"])
              )
            ) {
              this.project_edit_copy.tags_accepted.push(
                res.data["Directions"][obj]["id"]
              );
            } else {
              this.project_edit_copy.tags_awaiting_moderation.push(
                res.data["Directions"][obj]
              );
            }
          }
        }
      }
    },
    async changeCustomDirectionStatus(id, approve) {
      if (!approve) {
        try {
          await deleteDirection(id);
          ElNotification({
            title: "Отлично!",
            message: h(
              "i",
              { style: "color: green; z-index: 99999;" },
              "Направление удалено!"
            ),
            type: "success",
            position: "bottom-right",
          });
        } catch (e) {
          alert(e);
          ElNotification({
            title: "Упс!",
            message: h(
              "i",
              { style: "color: red; z-index: 99999;" },
              "Произошла ошибка!"
            ),
            type: "error",
            position: "bottom-right",
          });
        }
      } else {
        try {
          await updateDirection(id, { direction_status: 8 });
          ElNotification({
            title: "Отлично!",
            message: h(
              "i",
              { style: "color: green; z-index: 99999;" },
              "Направление одобрено!"
            ),
            type: "success",
            position: "bottom-right",
          });
        } catch (e) {
          alert(e);
          ElNotification({
            title: "Упс!",
            message: h(
              "i",
              { style: "color: red; z-index: 99999;" },
              "Произошла ошибка!"
            ),
            type: "error",
            position: "bottom-right",
          });
        }
      }
      await this.updateDirectionsList();
    },
    /* END Обновление списка направлений */

    /* Загрузка файлов (и фото) */
    handleAvatarUpload(file) {
      if (file.raw.type != "image/jpeg" && file.raw.type != "image/png") {
        ElNotification({
          title: "Ошибка!",
          message: h(
            "i",
            { style: "color: green; z-index: 99999;" },
            "Можно загружать только .png и .jpg файлы!"
          ),
          type: "error",
          position: "bottom-right",
        });
        return;
      }
      this.project_edit_copy.img = {
        url: URL.createObjectURL(file.raw),
        file: file.raw,
        name: file.name.replaceAll(" ", ""),
      };
    },
    handleAvatarDelete() {
      this.project_edit_copy.img = null;
    },
    handleFileUpload(file) {
      if (file.raw.type != "application/x-zip-compressed") {
        ElNotification({
          title: "Ошибка!",
          message: h(
            "i",
            { style: "color: green; z-index: 99999;" },
            "Можно загружать только .zip файлы!"
          ),
          type: "error",
          position: "bottom-right",
        });
        this.project_add_files_list = [];
        return;
      }
      this.project_edit_copy.file = {
        url: URL.createObjectURL(file.raw),
        file: file.raw,
        name: file.name.replaceAll(" ", ""),
      };
      this.project_add_files_list = [
        {
          url: URL.createObjectURL(file.raw),
          file: file.raw,
          name: file.name.replaceAll(" ", ""),
        },
      ];
    },
    handleRemove() {
      this.project_add_files_list = [];
    },
    /* END Загрузка файлов (и фото) */

    /* Работа с проектами */
    clearProjectEditForm() {
      this.project_edit_copy = {
        img: null,
        file: null,
        name: "",
        desc_short: "",
        desc_full: "",
        contacts: "",
        comment: "",
        tags_accepted: [],
        tags_awaiting_moderation: [],
        status: null,
      };
    },
    projectHandleEdit(projectID) {
      this.project_mode_add = false;

      this.clearProjectEditForm();

      if (projectID == null) {
        ElNotification({
          title: "Ошибка!",
          message: h(
            "i",
            { style: "color: red; z-index: 99999;" },
            "ID направления почему-то не выбрано"
          ),
          type: "error",
          position: "bottom-right",
        });
        return;
      }
      this.project_selected_id = projectID;

      if (
        this.filter_projects.find(
          (el) => parseInt(el.id) == parseInt(projectID)
        )
      ) {
        let project = this.filter_projects.find(
          (el) => parseInt(el.id) == parseInt(projectID)
        );
        this.project_edit_copy.name = project["project_name"];
        this.project_edit_copy.desc_short = project["project_short_desc"];
        this.project_edit_copy.desc_full = project["project_full_desc"];
        this.project_edit_copy.contacts = project["project_contacts"];
        this.project_edit_copy.status = 7;
        this.project_edit_copy.comment = project["moderation_comment"];

        if (project.hasOwnProperty("OPMaterial")) {
          if (project["OPMaterial"]["project_img"] != null) {
            this.project_edit_copy.img = {
              url: project["OPMaterial"]["project_img"],
              name: "loaded",
              file: "loaded",
            };
          }

          if (project["OPMaterial"]["project_file"] != null) {
            this.project_edit_copy.file = {
              url: project["OPMaterial"]["project_file"],
              name: "loaded",
              file: "loaded",
            };

            this.project_add_files_list = [
              {
                name: project["OPMaterial"]["project_file"]
                  .replaceAll("\\", "/")
                  .substr(
                    project["OPMaterial"]["project_file"]
                      .replaceAll("\\", "/")
                      .indexOf("organization_projects") +
                      "organization_projects/".length
                  ),
                url: project["OPMaterial"]["project_file"].replaceAll(
                  "\\",
                  "/"
                ),
              },
            ];
          }
        }

        if (project.hasOwnProperty("Directions")) {
          for (let obj in project["Directions"]) {
            if (
              this.all_directions.find(
                (el) =>
                  parseInt(el.id) == parseInt(project["Directions"][obj]["id"])
              )
            ) {
              this.project_edit_copy.tags_accepted.push(
                project["Directions"][obj]["id"]
              );
            } else {
              this.project_edit_copy.tags_awaiting_moderation.push(
                project["Directions"][obj]
              );
            }
          }
        }
      } else {
        //TODO: Если проект не найден
        return;
      }

      this.dialog_edit_show = true;
    },
    projectHandleDelete(projectID) {
      this.project_selected_id = projectID;
      this.dialog_delete_show = true;
    },
    async projectAdminDelete() {
      if (this.project_selected_id == null) {
        ElNotification({
          title: "Ошибка!",
          message: h(
            "i",
            { style: "color: red; z-index: 99999;" },
            "ID направления почему-то не выбрано"
          ),
          type: "error",
          position: "bottom-right",
        });
        return;
      }

      if (
        this.filter_projects.find(
          (el) => parseInt(el.id) == parseInt(this.project_selected_id)
        )
      ) {
        try {
          await deleteProject({ project_id: this.project_selected_id });
          ElNotification({
            title: "Успешно!",
            message: h(
              "i",
              { style: "color: green; z-index: 99999;" },
              "Проект удален!"
            ),
            type: "success",
            position: "bottom-right",
          });
        } catch (e) {
          alert(e);
        }
        this.dialog_delete_show = false;
        this.dialog_edit_show = false;
        await this.getProjectsList();
      } else {
        ElNotification({
          title: "Ошибка!",
          message: h(
            "i",
            { style: "color: red; z-index: 99999;" },
            `Проект (id: ${this.project_selected_id}) - не найден в текущем списке!`
          ),
          type: "error",
          position: "bottom-right",
        });
      }
    },
    async projectAdminEdit() {
      if (this.project_selected_id == null) {
        ElNotification({
          title: "Ошибка!",
          message: h(
            "i",
            { style: "color: red; z-index: 99999;" },
            "ID направления почему-то не выбрано"
          ),
          type: "error",
          position: "bottom-right",
        });
        return;
      }
    },
    checkValidation() {
      let checkFields = ["name", "desc_short", "desc_full"];
      for (let keyIndex in checkFields) {
        if (this.project_edit_copy[checkFields[keyIndex]] == "") {
          this.validationErrorMessage("Не заполнены все поля проекта!");
          return;
        }
      }

      if (this.project_mode_add == false) {
        if (this.project_edit_copy.tags_awaiting_moderation.length != 0) {
          this.validationErrorMessage(
            "Вы не одобрили/отклонили все предложенные направления в проекте!"
          );
          return;
        }

        if (this.project_edit_copy.status == 7) {
          if (this.project_edit_copy.comment == "") {
            this.validationErrorMessage(
              "Не заполнен комментарий итоговой проверки!"
            );
            return;
          }
        }
      }

      if (this.project_mode_add == true) {
        if (this.project_bind_organization == null) {
          this.validationErrorMessage(
            "Выберите организацию для создания проекта!"
          );
          return;
        }
      }

      this.sendProject();
    },
    validationErrorMessage(text) {
      ElNotification({
        title: "Ошибка валидации!",
        message: h("i", { style: "color: red; z-index: 99999;" }, text),
        type: "error",
        position: "bottom-right",
      });
    },
    async sendProject() {
      const formData = new FormData();

      if (this.project_mode_add) {
        if (
          this.project_edit_copy.img != null &&
          this.project_edit_copy.img.file != "loaded"
        ) {
          formData.append(
            "image",
            this.project_edit_copy.img.file,
            this.project_edit_copy.img.name
          );
        } else {
          formData.append("image", null);
        }

        if (this.project_add_files_list.length == 0) {
          formData.append("file", null);
          formData.append("file_delete", "yes");
        } else {
          if (
            this.project_edit_copy.file &&
            this.project_edit_copy.file.file != "loaded"
          ) {
            formData.append(
              "file",
              this.project_edit_copy.file.file,
              this.project_edit_copy.file.name
            );
            formData.append("file_delete", "no");
          } else {
            formData.append("file", null);
            formData.append("file_delete", "no");
          }
        }
      }

      if (!this.project_mode_add) {
        formData.append("project_id", this.project_selected_id);
        formData.append("status", this.project_edit_copy.status);
        formData.append("comment", this.project_edit_copy.comment);
        formData.append("file_delete", "no");
      } else {
        formData.append("org_id", this.project_bind_organization);
      }

      formData.append("project_name", this.project_edit_copy.name);
      formData.append("project_short_desc", this.project_edit_copy.desc_short);
      formData.append("project_full_desc", this.project_edit_copy.desc_full);
      formData.append(
        "directions_approved",
        this.project_edit_copy.tags_accepted
      );
      formData.append("directions_custom", []);
      formData.append("contact_data", this.project_edit_copy.contacts);

      if (!this.project_mode_add) {
        await updateProject(formData)
          .then((resp) => {
            ElNotification({
              title: "Успешно!",
              message: h(
                "i",
                { style: "color: green; z-index: 99999;" },
                "Проект изменен!"
              ),
              type: "success",
              position: "bottom-right",
            });
            this.getProjectsList();
          })
          .catch((e) => {
            ElNotification({
              title: "Ошибка!",
              message: h(
                "i",
                { style: "color: red; z-index: 99999;" },
                "Что-то пошло не так!"
              ),
              type: "error",
              position: "bottom-right",
            });
            console.log(e);
          });
      } else {
        await createProject(formData)
          .then((resp) => {
            ElNotification({
              title: "Успешно!",
              message: h(
                "i",
                { style: "color: green; z-index: 99999;" },
                "Проект добавлен!"
              ),
              type: "success",
              position: "bottom-right",
            });
            this.getProjectsList();
            this.dialog_edit_show = false;
            this.project_mode_add = false;
          })
          .catch((e) => {
            ElNotification({
              title: "Ошибка!",
              message: h(
                "i",
                { style: "color: red; z-index: 99999;" },
                "Что-то пошло не так!"
              ),
              type: "error",
              position: "bottom-right",
            });
            console.log(e);
          });
      }
      this.dialog_delete_show = false;
      this.dialog_edit_show = false;
    },
    /* END Работа с проектами */

    /* Check user & auth fail */
    async updateUser() {
      try {
        const response = await refresh();
        const userResponse = response.user;
        this.getUser(userResponse.id);
      } catch (e) {
        alert(
          "Вы не авторизованы, скорее всего требуется повторная авторизация"
        );
      }
    },
    getUser(id) {
      findById(id).then((response) => {
        this.auth_user = JSON.parse(JSON.stringify(response.data));
        this.SET_USER(response.data);
        if (this.auth_user == null) {
          alert("Время сессии истекло. Требуется повторная авторизация");
          return;
        }
        //TODO: ПРОВЕРКА НА АДМИНА
        this.getApprovedDirectionsList();
        this.getOrganizationsList();
        this.getProjectsList();
      });
    },
    /* END Check user & auth fail */
  },
};
</script>

<style scoped>
.project-filters > div {
  margin: 0% 1vw;
}
.project-filters > div:nth-child(3) {
  margin: auto 1vw 0% 1vw;
}

.custom_direction_list > div > * {
  margin: 0.5vh auto;
}
.custom_direction_list .badge {
  font-size: 1vw;
}

.dialog-footer {
  text-align: center;
}

.project-moderation > div {
  margin-bottom: 12vh;
}

.project-moderation
  > div:not(:nth-child(1))
  > .details-body
  > div:nth-child(1) {
  margin-top: 4vh;
}
.project-moderation > div:not(:nth-child(1)) > .details-body > div {
  margin-bottom: 6vh;
}
.project-moderation > div:nth-child(2) > .details-body > div {
  text-align: left;
}

.details-body > div {
  margin: 0% auto;
  width: 90%;
}
.details-body .el-select {
  width: 100%;
}

.project-img-block {
  width: 400px;
  height: 300px;
  background-position: 50% 50% !important;
  background-repeat: no-repeat;
  background-size: 400px auto !important;

  margin: 4vh auto !important;
}

.bg-picture {
  position: fixed;

  top: 0%;
  left: 0%;

  width: 100%;
  height: 100%;

  background-color: white !important;
  background-image: url(/img/karakul2.6c696f6f.svg) !important;
  background-position: 100% 135% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: 30% !important;

  z-index: 1;
}
.admin-organization-projects {
  position: relative;

  top: 75px;
  left: 0%;
}

.admin-organization-projects > .admin-content {
  position: relative;
  z-index: 5;

  width: 100%;
}
.admin-organization-projects > .admin-content:not(:first-child) {
  margin-top: 60px;
}

.admin-content > .el-container .el-row {
  justify-content: center;
}

.ulr-center-block {
  display: flex;
  justify-content: center;

  margin: auto;
  margin-bottom: 30px;
}
.flex-column {
  flex-direction: column;
}
</style>
